import React, { useContext, useState, useRef, useCallback, useEffect } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import { DateTimePickerComponent, DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import NewBookingCustomFields from "./NewBookingCustomFields";
import EditTr from "./EditTr";
import { FaRegTrashAlt, FaEdit } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import GuestListShow from "./GuestListShow";
import moment from "moment";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { Spinner } from "reactstrap";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

const NewBookingPopupTemplate = () => {
  const {
    scheduler_Data,
    propertyID,
    clientID,
    token,
    proxy_Url,
    User_Details,
    tr_select_details,
    getTrSelectDetails,
    isLoadingfunc,
    textNum,
    errorAcceptButtonfunc,
    errorbuttonvalidationmsg,
    errorvalidationButton,
    gridoccupencydatafunc,
    property_details,
    staydayscountfunc,
    staydayscount,
    staydaystodisplay,
    staydaystodisplayfunc,
    editorcancelenddatefunc,
    showNewBookingfunc,
    //shownewbooking,
    setShowTrfunc,
    //showTr,
    seteditordata,
    setEditordatafunc,
    new_booking_popupdata,
    new_booking_data,
    getghroomnamefunc,
    ghroom_name,
    getghroomidfunc,
    ghroom_id,
    room_id,
    ClientFlagDetails,
    GH_property_ClientName,
    ghaccessdata,
    getErrorMessagefunc,
    set_Error_Message_func,
    // clientDetails,
    client_id,
    setErrorMsgClassfunc,
    ghName,
    getPropertyDetails,
    propInput,
    clientfulldetails,
  } = useContext(BookingContent);
  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  let getNew_bookingPopupData = new_booking_popupdata;
  let new_bookingdata_details = new_booking_data;
  //to get roomdata from grid
  let getRoomId = scheduler_Data[0]?.roomData?.filter((item) => item.id === room_id) || [{}];

  const [new_C_Fields, setNew_C_Fields] = useState(false);
  const [show_tr_count, setShow_Tr_count] = useState(0);
  const [add_tr_list, setAdd_tr_list] = useState([]);
  const [gettr_fromstorage, setgettr_fromstorage] = useState([]);
  //guest select
  const [guestselect, setGuestselect] = useState([]);
  const [guestvalueselect, setGuestvalueselect] = useState([]);
  const [showguestselect, setShowGuestselect] = useState(false);
  const [validemail, setvalidemail] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  let valid_email = true;
  let validcc_email = true;
  const [errorAcceptButton, seterrorAcceptButton] = useState(false);
  const [malefemale_but, setmalefemale_but] = useState(false);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [malefemale, setmalefemale] = useState([]);
  const [getstaydaysexceed, setgetstaydaysexceedfunc] = useState([]);
  const [time_change, settime_changefunc] = useState("");
  const [time_changeout, settime_changeoutfunc] = useState("");
  const [mobilenumber, setmobilenumber] = useState();
  const [timediff, settimediff] = useState(5);
  const [bedCount, setBedCount] = useState();
  const [availbedCount, seAvailBedCount] = useState(null);
  const [block, setblock] = useState(0);
  const [changelog, setchangelog] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [datecount, setdatecount] = useState(0);
  const [enddatereset, setenddatereset] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [staydayblock, setstaydayblock] = useState("");
  const [dateco, setdateco] = useState(null);
  const [dupchko, setdupchko] = useState([]);
  const [guestnotallowed, setguestnotallowed] = useState([]);
  const [guesterrormsg, setguesterrormsg] = useState([]);
  const [duplicatecheck, setduplicatecheck] = useState([]);
  const [noroomavailable, setnoroomavailable] = useState([]);
  const [mincheckouttime, setmincheckouttime] = useState([]);

  const [Cdate, setDate] = useState(new Date().toLocaleDateString("fr-FR"));
  const [loadingspinner, setloadingspinner] = useState(false);
  const [bedbookinggendershow, setbedbookinggendershow] = useState(0);
  const [bedbookingdatasend, setbedbookingdatasend] = useState({});
  const [addlist, setaddlist] = useState(0);

  // gender check for bed booking
  const [gender, setgender] = useState([]);
  const [genderbchked, setGenderbched] = useState(0);
  const [genderbooking, setgenderbooking] = useState(0);
  const [displaygender, setdisplaygender] = useState("");
  const [changeouttime, setchangeouttime] = useState("11:59:00 AM");
  const [maxtime, setMaxTime] = useState("11:00:00 PM");
  const [chkinout, setchkinout] = useState([]);
  const [guestedit, setguestedit] = useState([]);
  const [guestediable, setguestediable] = useState(false);
  //const [editingIndex, setEditingIndex] = useState(null);

  const [editingIndex, setEditingIndex] = useState(null);
  const [guestEditable, setGuestEditable] = useState(false);
  const [client_f_detail, setclient_f_detail] = useState([]);
  const [showedittr, setShowedittr] = useState(false);
  const [edittrval, setedittrval] = useState([]);
  const [editindex, seteditindex] = useState([]);
  const inputRefs = useRef([]);
 
  let booking_msg;
  const [msg_option, setmsg_option] = useState([]);
  // check room availability
  let ssdate = [seteditordata?.sdate, ghroom_id];
  let gethrs;
  let intime;
  let intimeampm;
  let outtime;
  let outtimeampm;
  let getouthrs;
  let intimeobj = useRef();
  let outdateObj = useRef();
  let endObj = useRef();
  let startObj = useRef();
  let endresetdate;

  //emp guest empcode set
  let Sdatref = useRef();
  let Edatref = useRef();
  let emp_code = useRef();
  let emp_fname = useRef();
  let emp_lname = useRef();
  let emp_email = useRef();
  let emp_mobile = useRef();
  let allowed_guest = useRef();
  let changetodate =
    getNew_bookingPopupData.StartTime.toLocaleDateString() === getNew_bookingPopupData.EndTime.toLocaleDateString()
      ? new Date(
          new Date(getNew_bookingPopupData.EndTime).setDate(new Date(getNew_bookingPopupData.EndTime).getDate() + 1)
        )
      : getNew_bookingPopupData.EndTime;

  const [dd, setdd] = useState(changetodate);
  const [editorInput, setEditorInput] = useState({
    TRrequestid: "",
    BType: "",
    chkindate: getNew_bookingPopupData.StartTime,
    chkoutdate: changetodate,
    blockroom: "",
    guestAllowed: "",
    occupancy: "",
    ClientRequest: "",
    ExtraccMail: "",
  });

  let guest_initial_state = {
    EmpCode: "",
    Title: "",
    FirstName: "",
    LastName: "",
    Grade: "",
    Designation: "",
    EmailId: "",
    MobileNo: "",
  };

  const [guest_editor, setguest_editor] = useState(guest_initial_state);
  const [guest_list, setguest_list] = useState([]);
  const [roomAvailable, setroomAvailable] = useState("");
  const [ecount, setecount] = useState("");
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  // let diff = 0;
  let MaxGuestAllowed = document.querySelector("#MaxGuestAllowed");
  let EventType = document.querySelector("#EventType");
  let Occupancy = document.querySelector("#Occupancy");
  let occupency_disable = document.querySelector(".occupency_disable");
  let occupency_disable_span = document.querySelector(".occupency_disable > span");
  let checkouttimedisable = document.querySelector("#checkouttime");
  let chkotdatetime = document.querySelector(".chkotdatetime");
  let blocked_booking = document.querySelectorAll(".blocked_booking");

  let tMobileNo = document.querySelector("#tMobileNo");

  var current_Date = new Date();

  // Subtract 7 days
  current_Date.setDate(current_Date.getDate() - 7);

  // Output the result
  // get the grades
  const grades = clientfulldetails[0][2]?.map((item) => item.Grade);

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector("#EventType")?.value === "Room") {
        checkroomavail("Room");
      }

      timecheck({
        intime: timeformatcheck(document.querySelector("#checkintime")?.value),
        outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
      });
    }, 1000);
    document.querySelector("#BookingLevel").value = "Select";
    setEditingIndex(null);
    document.getElementsByClassName(`guestnameedit`).innerText = "";
    setAdd_tr_list([]);

    setclient_f_detail(grades);
  }, []);

  useEffect(() => {}, [ecount]);
  useEffect(() => {
    if (add_tr_list?.length > 0) {
      if (document.querySelector("#EventType").value === "Bed" && gender?.length > 0) {
        // if(!gender.includes((add_tr_list[0]?.Title  === "Male" ||add_tr_list[0]?.Title  === "Mr") ? 'Mr' : 'Ms')) {
        const title = add_tr_list[0]?.Title;
        let assignvalue = add_tr_list[0]?.Title === "Male" || add_tr_list[0]?.Title === "Mr" ? "Female" : "Male";
        if (gender?.length > 1) {
          setdisplaygender([assignvalue]);
          //  setGenderbched(1);
        } else if (title !== gender[0]) {
          setdisplaygender([assignvalue]);
          // setGenderbched(1);
        } else {
        }
      }
    }
  }, [gender]);
  useEffect(() => {
    //clear the data when tr changes
    // getTrSelectDetails([]);
    setgettr_fromstorage([]);
    setguest_editor({
      EmpCode: "",
      Title: "",
      FirstName: "",
      LastName: "",
      Grade: "",
      Designation: "",
      EmailId: "",
      MobileNo: "",
    });
    setAdd_tr_list([]);
    document.getElementsByClassName(`guestnameedit`).innerText = "";
    //clear the data when tr changes
    let empcode = document.querySelector(".css-1wa3eu0-placeholder");

    if (empcode) {
      empcode.innerHTML = tr_select_details[0][0]?.EmpCode || gettr_fromstorage?.EmpCode;
    }
    var Select_start_date = new Date(editorInput.chkindate);
    ClearDataFields();
    // Add a day
    Select_start_date.setDate(Select_start_date.getDate() + 1);
    let trdate = tr_select_details[0][0]?.CheckOutDate?.split("/");
    let trenddate = tr_select_details[0][0]?.CheckOutDate?.split("/");
    if (tr_select_details[0][0]?.CheckOutDate) {
      //remove document.querySelector("#StartTime").value = tr_select_details[0]?.CheckInDate;
      //remove  document.querySelector("#EndTime").value = tr_select_details[0]?.CheckOutDate;

      setEditorInput({
        ...editorInput,
        TRrequestid: tr_select_details[0][0]?.label || gettr_fromstorage?.label,

        chkintime: tr_select_details[0][0]?.CheckInTime || "12:00 PM",
      });

      let indate = tr_select_details[0][0]?.CheckInDate?.split("/");

      let sdatesplit = tr_select_details[0][0]?.CheckOutDate?.split("/");
      let sdate;
      if (sdatesplit?.length > 0) {
        sdate = new Date(sdatesplit[1] + "/" + sdatesplit[0] + "/" + sdatesplit[2]);
      }

      // set in and out time form tr
      settime_changefunc(setTRhrs(tr_select_details[0][0]?.CheckInTime));
      settime_changeoutfunc(setTRhrs(tr_select_details[0][0]?.CheckOutTime));

      setEditorInput({
        ...editorInput,
        TRrequestid: tr_select_details[0][0]?.label,
        chkindate: new Date(`${indate[1]},${indate[0]},${indate[2]}`) || getNew_bookingPopupData?.StartTime,
        chkoutdate: new Date(`${trenddate[1]},${trenddate[0]},${trenddate[2]}`),
        occupancy: tr_select_details[0]?.length === 2 ? "Double" : "Single",
      });
      MaxGuestAllowed.value = tr_select_details[0]?.length;

      let date = new Date(tr_select_details[0][0]?.CheckInDate);
      date.setDate(date.getDate() + 1);

      timecheck({
        intime: timeformatcheck(document.querySelector("#checkintime")?.value),
        outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
      });
    }

    // time delay to update date in end date

    if (typeof tr_select_details[0][0]?.CheckOutDate === "string") {
      //   setTimeout(()=>{
      //   // setEditorInput(pre => ({
      //   //   ...pre,
      //   //   chkoutdate: new Date(`${trenddate[1]},${trenddate[0]},${trenddate[2]}`),

      //   // }));

      // },2000)

      checkroomavail("Room");
    }
    // time delay to update date in end date
    let sdate_split = document.querySelector("#StartTime").value.split(" ");
    let edate_split = document.querySelector("#EndTime").value.split(" ");
    // Create new Date instance

    let stdate = sdate_split[0].split("/");
    let eddate = edate_split[0].split("/");

    stdate = new Date(stdate[1] + "/" + stdate[0] + "/" + stdate[2]);
    eddate = new Date(eddate[1] + "/" + eddate[0] + "/" + eddate[2]);

    var Difference_In_Time = stdate.getTime() - eddate.getTime();
    var Difference_In_Days = Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));
    staydayscountfunc(Difference_In_Days);

    if ((tr_select_details[0] && tr_select_details[0][0]?.Title !== undefined) || null) {
      //trselect data difference start
      let stdate_1 = tr_select_details[0] && tr_select_details[0][0]?.CheckInDate.split("/");
      let eddate_1 = tr_select_details[0] && tr_select_details[0][0]?.CheckOutDate.split("/");

      stdate_1 = new Date(stdate_1[1] + "/" + stdate_1[0] + "/" + stdate_1[2]);
      eddate_1 = new Date(eddate_1[1] + "/" + eddate_1[0] + "/" + eddate_1[2]);

      var Difference_In_Time = stdate_1.getTime() - eddate_1.getTime();
      var Difference_In_Days = Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));

      staydaystodisplayfunc(Difference_In_Days);

      //trselect data difference end

      let multitrdetails = tr_select_details[0]?.map((item) => ({
        EmpCode: item.EmpCode,
        Title: item?.Title === "Mr" ? "Mr" : "Ms",
        FirstName: item?.FirstName,
        LastName: item?.LastName,
        Grade: item?.Grade || "0",
        Designation: item?.Designation,
        EmailId: item?.EmpEmail,
        MobileNo: item?.EmpMobile,
        Column1: item?.Column1,
        Column2: item?.Column2,
        Column3: item?.Column3,
        Column4: item?.Column4,
        Column5: item?.Column5,
        Column6: item?.Column6,
        Column7: item?.Column7,
        Column8: item?.Column8,
        Column9: item?.Column9,
        Column10: item?.Column10,
        GradeId: item?.GradeId,
        EmployeeId: item?.EmpId,
      }));

      setAdd_tr_list([
        // {
        //   EmpCode: tr_select_details[0]?.EmpCode,
        //   Title: tr_select_details[0]?.Title,
        //   FirstName: tr_select_details[0]?.FirstName,
        //   LastName: tr_select_details[0]?.LastName,
        //   Grade: tr_select_details[0]?.Grade || "0",
        //   Designation: tr_select_details[0]?.Designation,
        //   EmailId: tr_select_details[0]?.EmpEmail,
        //   MobileNo: tr_select_details[0]?.EmpMobile,
        //   Column1: tr_select_details[0]?.Column1,
        //   Column2: tr_select_details[0]?.Column2,
        //   Column3: tr_select_details[0]?.Column3,
        //   Column4: tr_select_details[0]?.Column4,
        //   Column5: tr_select_details[0]?.Column5,
        //   Column6: tr_select_details[0]?.Column6,
        //   Column7: tr_select_details[0]?.Column7,
        //   Column8: tr_select_details[0]?.Column8,
        //   Column9: tr_select_details[0]?.Column9,
        //   Column10: tr_select_details[0]?.Column10,
        //   GradeId: tr_select_details[0]?.GradeId,
        //   EmployeeId: tr_select_details[0]?.EmpId,
        // },
        ...multitrdetails,
      ]);

      // setmobilenumber(tr_select_details[0]?.EmpMobile);
      setShow_Tr_count(1);
    }

    if (document.querySelector("#EventType").value === "Bed") {
      checkroomavailbed();
      //check gender on bed booking
      genderfunc();
    } else {
      checkroomavail("Room");
    }
    setEditingIndex(null);
    return () => {
      setAdd_tr_list([]);
    };
  }, [tr_select_details]);

  useEffect(() => {
    if (document.querySelector("#EventType").value === "Bed") {
      document.querySelector("#MaxGuestAllowed").value = parseInt(availbedCount) > 2 ? 2 : availbedCount;
    }
  }, [getRoomId[0], availbedCount]);
  useEffect(() => {
    if (getRoomId) {
      // setroomAvailable("");
    }
    setTimeout(() => {
      // setmobilenumber("");
    }, 1000);
  }, [ghroom_id, changetodate]);

  useEffect(() => {
    setAdd_tr_list([]);
    if (ghaccessdata[0]?.BookingResFlg === false) {
      setgetErrorMessagefunc(["You dont have access to book this room"]);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
      }, 2000);
    } else {
      setgetErrorMessagefunc([]);
    }
    //errorvalidationButton
    if (errorvalidationButton === false) {
      let date = document.querySelector("#StartTime")?.value.split(" ");
      //let year = date[0].getYear();
      setEditorInput((pre) => ({ ...pre, chkoutdate: changetodate }));
    }
    if (getRoomId) {
      if (`${getRoomId[0]?.RoomId}` !== undefined) {
        getghroomidfunc(`${getRoomId[0]?.RoomId}`);
      }
      if (`${getRoomId[0]?.text}` !== undefined) {
        getghroomnamefunc(`${getRoomId[0]?.text}`);
      }
    }
    // new booking custom fields load

    //$(".hide_emp")
    $("body")
      .not(".guestshow")
      .click(() => {
        setShowGuestselect(false);
      });
  }, [getRoomId && getRoomId[0]?.text, getRoomId && getRoomId[0]?.RoomId, errorvalidationButton === false]);

  const setfourhrs = () => {
    setTimeout(() => {
      let sdate = document.querySelector("#StartTime")?.value.split(" ");
      let edate = document.querySelector("#EndTime")?.value.split(" ");
      let checkintime = document.querySelector("#checkintime")?.value;

      const dynamicTime = checkintime?.length > 2 ? checkintime : "12:00 PM";

      // Split the time string into hours, minutes, and period (AM/PM)
      const [timeHours, timeMinutes, period] = dynamicTime.split(/:| /);

      // Convert hours and minutes to numbers
      let hours = parseInt(timeHours);
      let minutes = parseInt(timeMinutes);

      // Adjust hours for PM time
      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      } else {
      }

      // Create a new Date object representing the current date and time
      let n_date = new Date();
      let m_date = new Date();

      if (GH_property_ClientName[0][1][0].CheckIn24Hrs === true && sdate[0] === edate[0]) {
        /// Create a new Date object representing the current date and time
        // Dynamic time value

        // Set the time to the current date
        n_date.setHours(hours + 4, minutes);
        setchangeouttime(n_date);
        settime_changeoutfunc(n_date);
        m_date.setHours(19, 0);
        setMaxTime(m_date);
      } else {
        n_date.setHours(0, 0);
        setchangeouttime(n_date);
        m_date.setHours(23, 0);
        setMaxTime(m_date);
      }
    }, 1000);
  };

  //set time for tr
  const setTRhrs = (trtime) => {
    const dynamicTime = trtime;

    // Split the time string into hours, minutes, and period (AM/PM)
    const [timeHours, timeMinutes, period] = dynamicTime.split(/:| /);

    // Convert hours and minutes to numbers
    let hours = parseInt(timeHours);
    let minutes = parseInt(timeMinutes);

    // Adjust hours for PM time
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    } else {
    }

    // Create a new Date object representing the current date and time
    let n_date = new Date();

    /// Create a new Date object representing the current date and time
    // Dynamic time value

    // Set the time to the current date
    n_date.setHours(hours, minutes);
    return n_date;
  };

  const EventoutDateChange =
    //useCallback(
    (e) => {
      //4 hrs time set
      setfourhrs();

      timecheck({
        intime: timeformatcheck(document.querySelector("#checkintime")?.value),
        outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
      });
      // setEditorInput((pre) => ({ ...pre, chkoutdate: new Date(new Date().setDate(new Date().getDate() + 1))}));
      if (document.querySelector("#EventType").value === "Bed") {
        checkroomavailbed();
        genderfunc();
      } else {
        checkroomavail("Room");
      }

      errorbuttonvalidationmsg(true);
      let e_name = e.target.id;
      let sdate_split = document.querySelector("#StartTime").value.split("/");
      let edate_split;

      if (e_name === "EndTime") {
        setEditordatafunc({ ...seteditordata, sdate: document.querySelector("#EndTime").value });
        edate_split = document.querySelector("#EndTime").value.split("/");
      } else {
      }
      let stdate = new Date(sdate_split[1] + "/" + sdate_split[0] + "/" + sdate_split[2]);
      let eddate = new Date(edate_split[1] + "/" + edate_split[0] + "/" + edate_split[2]);
      var Difference_In_Time = stdate.getTime() - eddate.getTime();
      var Difference_In_Days = Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));
      let propty_name = document.querySelector("#ghproperty")?.value;

      staydayscountfunc(Difference_In_Days);
      staydaystodisplayfunc(Difference_In_Days);

      if (propty_name.toLowerCase().includes("icici") && GH_property_ClientName[0][1][0].ICICIPropertyFlag === true) {
        if (Math.abs(Difference_In_Days) > 15) {
          setgetstaydaysexceedfunc([`Stay days exceeds 15 days do you want to continue?`]);
          setmsg_option("Alert");
          setstaydayblock("staydaysblock");
          //staydaystodisplayfunc(Difference_In_Days);
          document.querySelector("#Occupancy").style.webkitTextFillColor = "#000";
          seterrorAcceptButton(true);
        }
      } else {
        if (Math.abs(Difference_In_Days) > 60) {
          setgetErrorMessagefunc([]);
          setmsg_option("Alert");
          errorAcceptButtonfunc(true);
          setgetstaydaysexceedfunc([`Stay days exceeds 60 days do you want to continue?`]);
          document.querySelector("#Occupancy").style.webkitTextFillColor = "#000";
          //staydaystodisplayfunc(Difference_In_Days);
          seterrorAcceptButton(true);
        } else {
          //staydaystodisplayfunc(Difference_In_Days);
          setEditorInput((pre) => ({
            ...pre,
            chkoutdate: new Date(e.value),
            //chk and remove chkoutdate: new Date(e.value) || document.querySelector("#EndTime")?.value,
          }));

          seterrorAcceptButton(false);
        }
      }
      if (document.querySelector("#EventType").value === "Bed") {
        setTimeout(() => {
          checkroomavailbed();
          genderfunc();
        }, 1000);
      } else {
        checkroomavail("Room");
      }

      setmsg_option("Error");
      setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
    };
  //, []);

  // gender check for bed booking

  const bedbookinggendercheck = () => {
    setgender([]);
    setTimeout(() => {
      let sdate = document.querySelector("#StartTime")?.value.split(" ");
      let stime = document.querySelector("#checkintime");
      let edate = document.querySelector("#EndTime")?.value.split(" ");
      let etime = document.querySelector("#checkouttime");

      let chk_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt: sdate[0] + " " + (stime?.value !== undefined ? stime?.value.replace(":00", ":00:00") : "12:00:00 PM"),
        ToDt: edate[0] + " " + (etime?.value !== undefined ? etime?.value.replace(":00", ":00:00") : " 11:59:00 AM"),
        RoomId: getRoomId[0]?.RoomId || ghroom_id,
      };

      let genderresult;
      let result = axios
        .post(`${proxy_Url}/Bedbookinggendercheck`, chk_bodyData, { headers: headers })
        .then(async (res) => {
          if (res?.data?.Result === "No records found") {
            setgender([]);
          } else {
            // get gender from db and remove duplicate
            let getgenderarray = res?.data?.Result[0].map((item) => item.Title);
            let result_title_array = [...new Set(getgenderarray?.flat())].map((item) => (item === "Mr" ? "Mr" : "Ms"));
            setgender(result_title_array);
            genderresult = result_title_array;

            if (document.querySelector("#EventType").value === "Bed" && add_tr_list?.length === 1) {
              let findoppgender = result_title_array?.filter((item) => item !== add_tr_list[0]?.Title);
              if (findoppgender?.length > 0) {
                let assignvalue = findoppgender[0] === "Mr" ? "Male" : "Female";

                setdisplaygender([assignvalue]);
                setbedbookinggendershow(1);
              }
            }

            // if (add_tr_list?.length > 0) {

            //   if (add_tr_list[0]?.Title !== (res?.data?.Result[0][0]?.Title[0] === "Mr" ? "Mr" : "Ms")) {
            //     setGenderbched(1);
            //     setdisplaygender(gender[0][0]?.Title[0] === "Mr" ? "Male" : "Female");
            //   }
            // }
          }

          return genderresult;
        })
        .catch((error) => {
          console.log(error.message);
        });

      return result;
    }, 500);
  };

  const close = () => {
    if (changelog?.length > 0) {
      setshowchangelog(true);
    } else {
      if (editingIndex !== null) {
        setmsg_option("Error");
        setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
        setshowchangelog(true);
      } else {
        showNewBookingfunc(false);
        getTrSelectDetails([]);
        setgettr_fromstorage([]);
        setguest_editor({
          EmpCode: "",
          Title: "",
          FirstName: "",
          LastName: "",
          Grade: "",
          Designation: "",
          EmailId: "",
          MobileNo: "",
        });
        let emp_code = document.querySelector("#EmpCode")?.value;
        emp_code = "";
      }
    }
    setgender([]);
  };
  const checkroomavailbed = () => {
    setloadingspinner(true);
    isLoadingfunc(true);

    setTimeout(() => {
      let sdate = document.querySelector("#StartTime")?.value.split(" ");
      let stime = document.querySelector("#checkintime");
      let edate = document.querySelector("#EndTime")?.value.split(" ");
      let etime = document.querySelector("#checkouttime");

      let chk_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt: sdate[0] + " " + (stime?.value !== undefined ? stime?.value.replace(":00", ":00:00") : "12:00:00 PM"),
        ToDt: edate[0] + " " + (etime?.value !== undefined ? etime?.value.replace(":00", ":00:00") : " 11:59:00 AM"),
        RoomId: getRoomId[0]?.RoomId || ghroom_id,
      };

      if (document.querySelector("#EventType").value === "Bed") {
        axios
          .post(`${proxy_Url}/BedAvailableChk`, chk_bodyData, { headers: headers })
          .then(async (res) => {
            setroomAvailable(res?.data?.Result[0][0]?.Id ? "Available" : "Not available");

            seAvailBedCount(
              res?.data?.Result[0]?.length > 0 && res?.data?.Result[0] !== "N" ? res.data.Result[0]?.length : 0
            );
            setloadingspinner(false);
            isLoadingfunc(false);
          })
          .catch((error) => {});
      }
    }, 2000);
  };

  const checkroomavail = (value) => {
    setloadingspinner(true);
    isLoadingfunc(true);

    setTimeout(() => {
      let sdate = document.querySelector("#StartTime")?.value.split(" ");
      let stime = document.querySelector("#checkintime");
      let edate = document.querySelector("#EndTime")?.value.split(" ");
      let etime = document.querySelector("#checkouttime");

      let chk_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt: sdate[0] + " " + (stime?.value !== undefined ? stime?.value.replace(":00", ":00:00") : "12:00:00 PM"),
        ToDt: edate[0] + " " + (etime?.value !== undefined ? etime?.value.replace(":00", ":00:00") : " 11:59:00 AM"),
        RoomId: getRoomId[0]?.RoomId,
      };
      setroomAvailable("");

      if (
        document.querySelector("#EventType").value === "Room" ||
        document.querySelector("#EventType").value === "Blocked"
      ) {
        axios
          .post(`${proxy_Url}/RoomAvailableCheck`, chk_bodyData, { headers: headers })
          .then(async (res) => {
            setroomAvailable("");

            let availahcheck;
            setloadingspinner(false);
            isLoadingfunc(false);

            setroomAvailable(res?.data?.Result[0][0]?.Id ? "Available" : "Not available");

            roomAvailable("3");
          })
          .catch((error) => {});
      }
    }, 2000);
    // }
    // }
    //123 },1000);
    // }
  };

  //tr state change to show
  const trClick = () => {
    if (editingIndex === null) {
      setShowTrfunc(true);
      isLoadingfunc(true);
    } else {
      set_Error_Message_func(["Please save the current changes."]);
      setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
      setTimeout(() => {
        set_Error_Message_func([]);
      }, 2000);
    }
  };

  // date show on focus
  // const onFocus = (args) => {
  //   endObj.show();

  // };
  // const onFocussdate = (args) => {
  //  // startObj.show();

  //   setTimeout(() => {
  //     $(".checkindatetime .e-time-icon").click();
  //     document.querySelector(".checkindatetime .e-input-group-icon.e-time-icon.e-icons").click();
  //    }, 1000)

  // };
  const EventDateChange = (e) => {
    timecheck({
      intime: timeformatcheck(document.querySelector("#checkintime")?.value),
      outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
    });
    if (document.querySelector("#EventType").value === "Bed") {
      checkroomavailbed();
    } else {
      checkroomavail("Room");
    }
    setEditorInput((pre) => ({ ...pre, chkoutdate: new Date(new Date(e.value).setDate(new Date().getDate() + 1)) }));
  };

  // const add_userenteryGuestDetails = useCallback(
  const add_userenteryGuestDetails = (value) => {
    // let userentry_guest_details = {...guest_editor};
    let userentry_guest_details = document.querySelector("#FName");
    let ss = { FirstName: userentry_guest_details.value };

    setAdd_tr_list((prev) => [
      ...prev,
      {
        ...value,
        ...guestvalueselect,
        GradeId: gettr_fromstorage?.GradeId !== undefined ? gettr_fromstorage?.GradeId : "0",
        EmployeeId: gettr_fromstorage?.EmployeeId !== undefined ? gettr_fromstorage?.EmployeeId : "0",
      },
    ]);

    setguest_editor(guest_initial_state);
    setmobilenumber("");
  };

  const handelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditorInput((prev) => ({ ...prev, [name]: value }));
    if (document.querySelector("#Occupancy").value === "Single") {
      if (add_tr_list?.length > 1) {
        setEditorInput((pre) => ({ ...pre, occupancy: 2 }));
      } else {
        setEditorInput((pre) => ({ ...pre, occupancy: 1 }));
      }
    } else {
      setEditorInput((pre) => ({ ...pre, occupancy: 2 }));
    }
    if (name === "occupancy") {
      if (value === "Single") {
        MaxGuestAllowed.value = 1;
      } else {
        MaxGuestAllowed.value = 2;
        setEditorInput((pre) => ({ ...pre, occupancy: 2 }));
      }
    }
    if (name === "BType") {
      occupency_disable.classList.remove("e-disabled");
      occupency_disable_span.classList.remove("e-disabled");
      chkotdatetime.classList.remove("e-disabled");
      document.querySelector(".blocked-comments").style.display = "none";
      Occupancy.setAttribute("disabled", false);
      blocked_booking.forEach((item) => item.classList.remove("e-disabled"));
      blocked_booking.forEach((item) => item.classList.remove("d_none"));
      document.querySelector(".chkotdatetime").style.display = "block";
      document.querySelector("#Occupancy").setAttribute("disabled", false);
      document.querySelector("#checkouttime").classList.remove("e-disabledbed");
      document.querySelector(".chkotdatetime").classList.remove("e-disabledbed");
      document.querySelector(".checkindatetime").style.display = "block";
      setblock(0);
      if (value === "Room") {
        if (add_tr_list?.length > 1 && value === "Single") {
          Occupancy.value = "Single";
          MaxGuestAllowed.value = 1;
          setEditorInput((pre) => ({ ...pre, occupancy: "Single" }));
        } else if (add_tr_list?.length == 0) {
          MaxGuestAllowed.value = 1;
          setEditorInput((pre) => ({ ...pre, occupancy: "Single" }));
        } else if (add_tr_list?.length == 1) {
          MaxGuestAllowed.value = 1;
          setEditorInput((pre) => ({ ...pre, occupancy: "Single" }));
        } else {
          Occupancy.value = "Double";
          MaxGuestAllowed.value = 2;
          setEditorInput((pre) => ({ ...pre, occupancy: "Double" }));
        }
        checkroomavail("Room");

        Occupancy.setAttribute("disabled", false);
        document.querySelector("#Occupancy").style.webkitTextFillColor = "#000";
      } else if (value === "Bed") {
        checkroomavailbed();
        // editmodifyavailable();

        MaxGuestAllowed.value = 0 || parseInt(availbedCount) > 2 ? 2 : availbedCount;
        Occupancy.value = "Single";
        setEditorInput((pre) => ({ ...pre, occupancy: "Single" }));
        let e_time = new Date();
        // 24 hrs enabled reset to default time
        GH_property_ClientName[0][1][0].CheckIn24Hrs !== true && e_time.setHours(11, 59, 0);
        GH_property_ClientName[0][1][0].CheckIn24Hrs !== true && settime_changeoutfunc(new Date(e_time));
        occupency_disable.classList.add("e-disabled");
        occupency_disable_span.classList.add("e-disabled");
        // outtime disabled  chkotdatetime.classList.add("e-disabled");
        Occupancy.setAttribute("disabled", true);
        //  outtime disabled document.querySelector("#checkouttime").classList.add("e-disabledbed");
        // outtime disabled  document.querySelector(".chkotdatetime").classList.add("e-disabledbed");
        genderfunc();
        // gender check for bed booking
        // if (add_tr_list?.length > 0) {
        //   setTimeout(() => {

        //     if (add_tr_list[0]?.Title !== (gender[0][0]?.Title[0] === "Mr" ? "Mr" : "Ms")) {
        //       setdisplaygender(gender[0][0]?.Title[0] === "Mr" ? "Male" : "Female");

        //       setGenderbched(1);
        //     }
        //   }, 1000);
        // }
      } else {
        ClearDataFields();
        getTrSelectDetails([]);
        setgettr_fromstorage([]);
        setAdd_tr_list([]);

        MaxGuestAllowed.value = 0;
        Occupancy.value = "Single";
        occupency_disable.classList.add("e-disabled");
        occupency_disable_span.classList.add("e-disabled");
        Occupancy.setAttribute("disabled", true);
        blocked_booking.forEach((item) => item.classList.add("e-disabled"));
        blocked_booking.forEach((item) => item.classList.add("d_none"));
        document.querySelector(".blocked-comments").style.display = "block";
        setblock(1);
        if (GH_property_ClientName[0][1][0].CheckIn24Hrs === false) {
          document.querySelector(".checkindatetime").style.display = "none";
          document.querySelector(".checkindatetime").style.display = "none";
        }

        checkroomavail("Room");
      }
    }
    setmsg_option("Error");
    setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
  };

  document.addEventListener("DOMContentLoaded", (event) => {
    const gradeInput = document.querySelector('input[name="Grade"]');
    if (gradeInput) {
      gradeInput.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          e.preventDefault(); // Prevent form submission or button click when Enter is pressed
        }
      });
    }
  });
  const g_handelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // disable keyboard entry
    document.addEventListener("keydown", function (event) {
      // Check if the Enter key is pressed
      if (event.key === "Enter") {
        // Prevent the default action
        event.preventDefault();
      }
    });

    let m_no;
    if (name === "MobileNo") {
      if (!isNaN(e.target.value)) {
        m_no = e.target.value;
      }
    }
    // if (name === "Grade") {
    //   document.dispatchEvent(new MouseEvent('click'));
    // }
    setmsg_option("Error");
    setguest_editor((prev) => ({ ...prev, [name]: name !== "MobileNo" ? value : m_no }));
    setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
    //reove the guest details
    // setguest_list([]);
  };

  //guest select start

  const handleChange1 = (selOption) => {
    setguest_list([]);
    setgettr_fromstorage();
    document.querySelector("#tMobileNo").value = "";
    setmobilenumber("");
    setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
    isLoadingfunc(true);
    setTimeout(() => {
      isLoadingfunc(false);
    }, 1000);
    //remove  setguest_editor({ ...guest_editor, EmpCode: selOption.target.value });
    setguest_editor({
      ...guest_editor,
      Title: "",
      FirstName: "",
      LastName: "",
      Grade: "",
      Designation: "",
      EmailId: "",
      MobileNo: "",
    });
    setmobilenumber();

    let EmpCode = document.querySelector("#EmpCode")?.value;
    let clientname = document.querySelector("#Entity_select")?.value;
    let clientidfilter = GH_property_ClientName[0][0].filter((item) => item.ClientName === clientname)[0];

    let bodyData = {
      ClientId: clientidfilter?.Id,
      //|| clientID[0]?.length > 0 ? clientID[0] : client_id,
      EmpCode: selOption.target.value,
      //"EmpCode":`${empCodes}` || null
    };
    if (EmpCode?.length >= 3) {
      axios
        .post(`${proxy_Url}/EmployeeCodeLoads`, bodyData, { headers: headers })
        .then((res) => {
          setGuestselect(res.data.Result[0] !== "N" ? res.data.Result[0] : []);
          res.data.Result[0]?.length > 0 && res.data.Result[0] !== "N" && setShowGuestselect(true);

          isLoadingfunc(false);
        })
        .catch((error) => {
          if (error.response) {
            // console.error(error.message);
            setShowGuestselect(false);
            isLoadingfunc(false);
          }
        });
    } else {
      setShowGuestselect(false);
      isLoadingfunc(false);
    }
    if (ghaccessdata[0]?.BookingResFlg === false) {
      setgetErrorMessagefunc(["You dont have access to book this room"]);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
      }, 2000);
    } else {
      setgetErrorMessagefunc([]);
    }
  };

  //get guest details from guestlistshow

  const geGguestselect = (value) => {
    let value_modified = {
      ...value,
      Grade: value?.Grade != null && value?.Grade != "undefined" && value?.Grade != undefined ? value?.Grade : "",
    };
    let Grade_id = clientfulldetails[0][2].filter((item) => item.Grade === value_modified?.Grade);
    //get details from empcode
    setgettr_fromstorage(value_modified);

    setShowGuestselect(false);
    setguest_editor({
      ...guest_editor,
      EmpCode: value?.EmpCode,
      Title: value?.Title === "Mr" ? "Mr" : "Ms",
      FirstName: value?.FirstName,
      LastName: value?.LastName.trim(),
      Grade: value_modified?.Grade,
      GradeId: Grade_id[0]?.Id,
      Designation: value?.Designation,
      EmailId: value?.EmailId,
      MobileNo: value?.MobileNo,
    });

    setmobilenumber(value?.MobileNo);
    document.querySelector("#EmpCode").value = value.EmpCode;
    document.querySelector("#BookingLevel").value = value?.Title === "Mr" ? "Mr" : "Ms";

    setguest_list([value_modified]);
  };

  //form table submit
  const tablesubmit = (e) => {
    e.preventDefault();
  };

  //additional fields data for new booking

  const addAdditionalfields = (date) => {
    setgetErrorMessagefunc([]);
    setduplicatecheck([]);

    let EmpCode = document.querySelector("#EmpCode")?.value.trim();
    let Title = document.querySelector("#BookingLevel")?.value.trim();
    let FName = document.querySelector("#FName");
    let LName = document.querySelector("#LName");
    let tEmail = document.querySelector("#tEmail");
    let tMobileNo = document.querySelector("#tMobileNo");
    let trcount = document.querySelectorAll(".trcount");
    let Grade = document.querySelector("#Grade")?.value.trim();
    let Designation = document.querySelector("#Designation")?.value.trim();

    let trfilter = grades?.filter((item) => item == Grade);

    if (!(trfilter?.length > 0 || Grade == "" || Grade == null || Grade == undefined)) {
      setguesterrormsg([`Please select grade from the list`]);
      setTimeout(() => {
        setguesterrormsg([]);
      }, 2000);
      setmsg_option("Error");
      return;
    }
    let Grade_id = clientfulldetails[0][2].filter((item) => item.Grade === Grade);
    setGuestvalueselect({
      EmpCode: EmpCode,
      Title: Title,
      FirstName: FName?.value,
      LastName: LName?.value,
      Grade: Grade,
      GradeId: Grade_id[0]?.Id,
      Designation: Designation,
      EmailId: tEmail?.value,
      MobileNo: tMobileNo?.value,
    });
    if (FName?.value === "") {
      setguesterrormsg((pre) => [...pre, " First name is required"]);
      setmsg_option("Error");
    }
    if (Title === "Select") {
      setguesterrormsg((pre) => [...pre, " Title is required"]);
      setmsg_option("Error");
    }
    if (LName?.value === "") {
      setguesterrormsg((pre) => [...pre, " Last name is required"]);
      setmsg_option("Error");
    } else {
      if (LName?.value.length < 1) {
        setguesterrormsg((pre) => [...pre, " Last name should be minimum two characters"]);
        setmsg_option("Error");
      }
    }
    if (tEmail?.value === "") {
      setguesterrormsg((pre) => [...pre, " Email is required"]);
      setmsg_option("Error");
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(tEmail?.value)) {
        setguesterrormsg((pre) => [...pre, " Please enter a valid email"]);
        setmsg_option("Error");
        setvalidemail(false);
        valid_email = false;
      } else {
        setvalidemail(true);
        valid_email = true;
      }
    }
    if (tMobileNo?.value === "") {
      setguesterrormsg((pre) => [...pre, " Mobile no is required"]);
      setmsg_option("Error");
    } else {
      if (tMobileNo?.value?.length !== 10) {
        setguesterrormsg((pre) => [...pre, " Mobile no should be 10 digits"]);
        setmsg_option("Error");
      }
    }

    setTimeout(() => {
      error_close_noreset();
    }, 2000);
    let indateandtime = document.querySelector("#StartTime")?.value.split("/");
    let checkintime = document.querySelector("#checkintime")?.value;
    let EndTime = document.querySelector("#EndTime")?.value.split("/");
    let checkouttime = document.querySelector("#checkouttime")?.value;

    let dup_data = {
      finalGuest: parseInt(`${guest_list[0]?.GuestId}`) || 0,
      CheckInDt: indateandtime[2] + "-" + indateandtime[1] + "-" + indateandtime[0] + " " + checkintime,
      CheckOutDt: EndTime[2] + "-" + EndTime[1] + "-" + EndTime[0] + " " + checkouttime,
    };

    if (trcount.length < parseInt(MaxGuestAllowed.value)) {
      if (
        FName?.value === "" ||
        LName?.value === "" ||
        tEmail?.value === "" ||
        tMobileNo?.value === "" ||
        tMobileNo?.value?.length !== 10 ||
        validemail === false ||
        valid_email === false ||
        LName?.value?.length < 1 ||
        Title === "Select"
      ) {
        setNew_C_Fields(false);
      } else {
        isLoadingfunc(true);

        axios
          .post(`${proxy_Url}/GHDuplicateCheck`, dup_data, { headers: headers })
          .then((res) => {
            //remove

            // isLoadingfunc(false);

            if (res.data.Result === "No records found") {
              isLoadingfunc(false);
              let get_title = document.querySelector("#BookingLevel");
              let get_Email = document.querySelector("#tEmail");
              let title = [...new Set(add_tr_list?.map((item) => (item.Title === "Mr" ? "Male" : "Female")))];
              let gettitle_val = get_title?.value === "Mr" ? "Male" : "Female";
              let title_array = [...new Set([...title, gettitle_val])];

              //duplicate check
              // let duplicateData = add_tr_list?.map((item) =>
              //   (item.EmpEmail || item.Email || item.EmailId) === get_Email?.value ? true : false
              // );

              let duplicateData = add_tr_list?.filter(
                (item) =>
                  (item?.FirstName.trim() === FName?.value.trim() &&
                    item?.LastName.trim() === LName?.value.trim() &&
                    item?.MobileNo.trim() === tMobileNo?.value.trim() &&
                    item?.EmailId.trim() === tEmail?.value.trim()) ||
                  (parseInt(item?.EmployeeId.trim()) === parseInt(guest_list[0]?.GuestId.trim())) === true
                //&&
                // item?.EmployeeId.trim() ===
              );
              if (duplicateData?.length > 0) {
                setdupchko(["Employee code already exists"]);
                setTimeout(() => {
                  setdupchko([]);
                }, 2000);
                setmsg_option("Alert");
              } else {
                // if (title_array?.length > 1 && title_array?.includes("Male") && title_array?.includes("Female")) {
                // setmalefemale([`${title} guest was staying in that room, do you want to continue ?`]);

                if (
                  document.querySelector("#EventType").value === "Bed" &&
                  gender?.length > 0 &&
                  !gender.includes(title_array[0] === "Male" || title_array[0] === "Mr" ? "Mr" : "Ms")
                ) {
                  if (!gender.includes(title_array[0] === "Male" || title_array[0] === "Mr" ? "Mr" : "Ms")) {
                    let assignvalue = title_array[0] === "Male" || title_array[0] === "Mr" ? "Female" : "Male";

                    setmalefemale([` ${assignvalue} guest was staying in that room, do you want to continue..?`]);
                    setmalefemale_but(true);
                    setNew_C_Fields(false);
                  }
                } else {
                  if (title_array?.length > 1 && title_array?.includes("Male") && title_array?.includes("Female")) {
                    setmalefemale([`${title} guest was staying in that room, do you want to continue..?`]);

                    setmsg_option("Error");
                    setNew_C_Fields(false);
                    errorAcceptButtonfunc(false);
                    setmalefemale_but(true);
                  } else {
                    setmalefemale_but(false);

                    if (
                      new_booking_data[2][0]?.Column1 !== "" ||
                      new_booking_data[2][0]?.Column2 !== "" ||
                      new_booking_data[2][0]?.Column3 !== "" ||
                      new_booking_data[2][0]?.Column4 !== "" ||
                      new_booking_data[2][0]?.Column5 !== "" ||
                      new_booking_data[2][0]?.Column6 !== "" ||
                      new_booking_data[2][0]?.Column7 !== "" ||
                      new_booking_data[2][0]?.Column8 !== "" ||
                      new_booking_data[2][0]?.Column9 !== "" ||
                      new_booking_data[2][0]?.Column10 !== ""
                    ) {
                      setNew_C_Fields(true);
                    } else {
                      let gradeid = clientfulldetails[0][2].filter((item) => item.Grade === Grade);
  
                      setAdd_tr_list([
                        ...add_tr_list,
                        {
                          EmpCode: EmpCode,
                          Title: Title,
                          FirstName: FName?.value.trim(),
                          LastName: LName?.value.trim(),
                          Grade: Grade.trim(),
                          Designation: Designation.trim(),
                          EmailId: tEmail?.value.trim(),
                          MobileNo: tMobileNo?.value.trim(),
                          Column1: "",
                          Column2: "",
                          Column3: "",
                          Column4: "",
                          Column5: "",
                          Column6: "",
                          Column7: "",
                          Column8: "",
                          Column9: "",
                          Column10: "",
                          GradeId: gradeid[0]?.Id || "0",
                          EmployeeId: "0",
                        },
                      ]);

                      setguest_editor(guest_initial_state);
                      setmobilenumber("");
                      document.querySelector("#EmpCode").value = "";
                      //document.querySelector("#BookingLevel").value = 'Select';
                    }
                  }
                }
              }
            } else {
              let result = res.data.Result.split("<br>,");
              setduplicatecheck(["Guest already booked", ...result]);
              setTimeout(() => {
                error_close();
                setduplicatecheck([]);
                setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
              }, 2000);
              setmsg_option("Error");
              errorAcceptButtonfunc(false);
              setmalefemale_but(false);
              isLoadingfunc(false);
            }
          })
          .catch((error) => {});
      }
    } else {
      if (parseInt(allowed_guest.current.value) === 1) {
        setguesterrormsg([`Only 1 guest allowed for single occupancy`]);
      } else {
        setguesterrormsg([`Only 2 guest allowed for double occupancy`]);
      }
      // setguestnotallowed([" Guest not allowed"]);
      setTimeout(() => {
        setguesterrormsg([]);
      }, 2000);
      setmsg_option("Error");
    }
  };

  const ClearDataFields = () => {
    setguest_editor({
      EmpCode: "",
      Title: "",
      FirstName: "",
      LastName: "",
      Grade: "",
      Designation: "",
      EmailId: "",
      MobileNo: "",
    });
    document.querySelector("#EmpCode").value = "";
    document.querySelector("#tMobileNo").value = "";
    document.querySelector("#Grade").value = "";
    setmobilenumber("");
  };
  const ClearBooking = () => {
    //clear the trselect datas
    getTrSelectDetails([]);
    setgettr_fromstorage([]);
    setmobilenumber("");
    setguest_editor({
      EmpCode: "",
      Title: "",
      FirstName: "",
      LastName: "",
      Grade: "",
      Designation: "",
      EmailId: "",
      MobileNo: "",
    });
    document.querySelector("#EmpCode").value = "";
    document.querySelector("#ClientRequestNo").value = "";
    document.querySelector("#ExtraCCEmail").value = "";
    document.querySelector("#blockroom").value = "";

    // date reset start
    var date = new Date(getNew_bookingPopupData?.StartTime);
    date.setDate(date.getDate() + 1);
    setEditorInput((pre) => ({
      ...pre,
      TRrequestid: "",
      chkindate: getNew_bookingPopupData?.StartTime,
      chkoutdate: new Date(date),
      chkintime: "12:00 PM",
    }));

    document.querySelector("#StartTime").value = moment(getNew_bookingPopupData?.StartTime).format("DD/MM/YYYY");
    document.querySelector("#EndTime").value = moment(date).format("DD/MM/YYYY");

    // date reset end

    staydaystodisplayfunc(1);
    if (block === 0) {
      setEditorInput((pre) => ({ ...pre, BType: "Room", occupancy: "Single" }));
    } else {
      setEditorInput((pre) => ({ ...pre, BType: "Blocked", occupancy: "Single" }));
    }
    occupency_disable.classList.remove("e-disabled");
    occupency_disable_span.classList.remove("e-disabled");
    chkotdatetime.classList.remove("e-disabled");
    Occupancy.setAttribute("disabled", false);
    let d_time = new Date(date);
    d_time.setHours(12, 0, 0);

    settime_changefunc(new Date(d_time));
    // let e_time = new Date();
    // e_time.setHours(11, 59, 0);
    // settime_changeoutfunc(new Date(e_time));

    settime_changeoutfunc("11:59 AM");

    MaxGuestAllowed.value = 1;
  };

  const getAdditionalValue = (value) => {
    // setNew_add_fields(value);

    setguest_editor({
      EmpCode: "",
      Title: "",
      FirstName: "",
      LastName: "",
      Grade: "",
      Designation: "",
      EmailId: "",
      MobileNo: "",
    });
    document.querySelector("#EmpCode").value = "";
  };

  /*const editEmp = (val,index1) => {
  let gname = document.getElementById("guestnameedit").innerHTML;
  let g_g_name = document.getElementById("guestgradeedit").innerHTML;
  let g_d_name = document.getElementById("guestdesnedit").innerHTML;


  setEditingIndex(index1);
  let modifiedguest = {...val,EmployeeId:gname,Grade:g_g_name,Designation:g_d_name}
  setguestedit({val,index1});
  setguestediable(!guestediable)
 
  let modifieddata = add_tr_list?.map((item,index) => ((item.EmailId == val?.EmailId && 
    item?.MobileNo == val?.MobileNo && item?.FirstName == val?.FirstName &&
    item?.LastName == val?.LastName && index ==index1) === true ? {...item,EmployeeId:gname,Grade:g_g_name,Designation:g_d_name} : item)) 
  if(guestediable === false){
   
      setAdd_tr_list(modifieddata);
  
    }


}*/

  // const handleEditClick = (index) => {
 

  //         // Trigger blur on the input to ensure the value is saved
  //         const selectElement = document.getElementById(`guestnameedit-${index}`);
  //         if (selectElement) {
  //             selectElement.blur();
  //         }

  //         if (editingIndex === index) {
  //             // Save changes and exit edit mode
  //             const updatedList = add_tr_list.map((item, idx) => {
  //                 if (idx === index) {
  //                     const updatedItem = {
  //                         ...item,
  //                         EmpCode: document.getElementById(`guestnameedit-${index}`).innerText,
  //                         Grade: document.getElementById(`guestgradeedit-${index}`).value,
  //                         Designation: document.getElementById(`guestdesnedit-${index}`).innerText,
  //                     };
  //                     return updatedItem;
  //                 }
  //                 return item;
  //             });
  //            
  //             setAdd_tr_list(updatedList);
  //             setEditingIndex(null);
  //         } else {
  //             // Prevent opening another row for edit if one is already in edit mode
  //             if (editingIndex !== null) {
  //                 set_Error_Message_func(["Please save the current changes."]);
  //                 setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
  //                 setTimeout(() => {
  //                     set_Error_Message_func([]);
  //                 }, 2000);
  //                 return;
  //             }
  //             setEditingIndex(index);
  //         }
  //     };

  const handleEditClick = (val, index) => {
    setShowedittr(true);
    setedittrval(val);
    seteditindex(index);
  };

  
  // remove trlist from trlist
  const removeEmp = (val, index) => {
    const values = add_tr_list?.filter((item, i) => i !== index);
    // (item?.EmailId !== val?.EmailId && item?.MobileNo !== val?.MobileNo && item?.FirstName !== val?.FirstName && item?.LastName !== val?.LastName));

    if (values?.length > 0) {
      // (item?.EmailId !== val?.EmailId && item?.MobileNo !== val?.MobileNo && item?.FirstName !== val?.FirstName && item?.LastName !== val?.LastName));

      setAdd_tr_list(values);
    } else {
      setAdd_tr_list([]);
      getTrSelectDetails([]);
      //ClearBooking();
      let e_time = new Date();
      e_time.setHours(11, 59, 0);
      settime_changeoutfunc(new Date(e_time));
    }
  };

  const bedbooking = (data, gridData) => {
    axios
      .post(`${proxy_Url}/BedLevelBooking`, data, { headers: headers })
      .then((res) => {
        //clear the trselect datas
        //remove
        setgender([]);
        //if (res.data.Result === "Booked") {
        showNewBookingfunc(false);
        if (res.data.Result && res.data.Result === "Booked") {
          set_Error_Message_func([`Booking confirmed successfully, booking confirmation no: ${res.data.BookingCode}`]);

          setTimeout(() => {
            set_Error_Message_func([]);
          }, 5000);
          setGuestvalueselect([]);
        } else if (res.data?.data && res.data?.data !== "") {
          set_Error_Message_func([res.data?.data]);
          setTimeout(() => {
            set_Error_Message_func([]);
          }, 2000);
          setGuestvalueselect([]);
        } else {
          set_Error_Message_func([res.data.Result]);
          setTimeout(() => {
            set_Error_Message_func([]);
          }, 2000);
          setGuestvalueselect([]);
        }
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });

        gridoccupencydatafunc(gridData);
        getTrSelectDetails({});
        close();
        // clear tr details
        setAdd_tr_list([]);
        isLoadingfunc(false);
        setroomAvailable("");

        //change log error msg
        setchangelog([]);
        setshowchangelog(false);
        //close booking template
        showNewBookingfunc(false);
        //show error msg
        setbedbookinggendershow(0);
        setgenderbooking(0);
      })
      .catch((error) => {
        // setgetErrorMessagefunc(["Booking was not successful"]);
        //set_Error_Message_func(["Booking was not successful"])
        setmsg_option("Error");
        setgetErrorMessagefunc(["Booking was not successful"]);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
        }, 2000);
        close();
        isLoadingfunc(false);

        setroomAvailable("");

        //change log error msg
        setchangelog([]);
        setshowchangelog(false);
      });
  };
  //collect data for booking
 
  const saveBooking = (e) => {
    let cintime = document.querySelector("#checkintime")?.value.trim();
    let couttime = document.querySelector("#checkouttime")?.value.trim();
    let cindate = document.querySelector("#StartTime")?.value.trim();
    let coutdate = document.querySelector("#EndTime")?.value.trim();
    // && cindate !== '' ||  coutdate !== ''

    if (cintime !== "" && (GH_property_ClientName[0][1][0].CheckIn24Hrs !== true || couttime !== "")) {
      e.preventDefault();

      document.querySelector("#EventType").value === "Room" ? checkroomavail("Room") : checkroomavailbed();

      let tr_list_count = document.querySelector(".trcount");

      let startDate = document.querySelector("#StartTime").value.slice(0, 10);
      let startTime = document.querySelector("#StartTime").value.slice(11, 19);
      let endDate = document.querySelector("#EndTime").value.slice(0, 10);
      let endTime = document.querySelector("#EndTime").value.slice(11, 19);
      let s_dates = startDate.split("/");
      let e_dates = endDate.split("/");
      let tr_count = 0;

      //
      let e_EmpCode = document.querySelector("#EmpCode")?.value.trim();
      let e_Title = document.querySelector("#BookingLevel")?.value.trim();
      let e_FName = document.querySelector("#FName")?.value.trim();
      let e_LName = document.querySelector("#LName")?.value.trim();
      let e_tEmail = document.querySelector("#tEmail")?.value.trim();
      let e_tMobileNo = document.querySelector("#tMobileNo")?.value.trim();
      let e_Grade = document.querySelector("#Grade")?.value.trim();
      let e_Designation = document.querySelector("#Designation")?.value.trim();

      const { chkindate, chkoutdate, TRrequestid, ClientRequest, ExtraccMail, BType, occupancy, guestAllowed } =
        editorInput;
      //data fpr booking ( room , bed )

      //get the room name for room booking
      let getroomnamedetails = scheduler_Data[0]?.roomData?.filter((item) => item.text === ghroom_name);
      let getroomgroupnamedetails = scheduler_Data[0]?.groupData?.filter(
        (item) => parseInt(item.ProjectGroupId) === parseInt(getroomnamedetails[0]?.groupId)
      );

      //change first letter to caps and add roomcaptured

      let guestcapsletter = add_tr_list.map((item, i) => ({
        ...item,
        FirstName: item.FirstName.charAt(0).toUpperCase() + item.FirstName.slice(1),
        RoomCaptured: i + 1,
        Grade: item.Grade != "Select" ? item.Grade : "",
        GradeId:
          item.Grade !== "" && item.Grade !== null && item.Grade !== undefined
            ? clientfulldetails[0][2].filter((val) => val.Grade === item.Grade)[0]?.Id
            : "0",
      }));
      let checkout =
        document.querySelector("#checkouttime")?.value !== ""
          ? document.querySelector("#checkouttime")?.value.split(":")
          : "11:59 AM".split(":");

      let checkoutampm = checkout[0].length > 1 ? checkout[0] : "0" + checkout[0];

      // let blk_roomname =
      //add block name to ghname
      let blocknameadd;
      if (ghroom_name.includes("- Single")) {
        blocknameadd = getRoomId[0]?.BlockName + " - " + ghroom_name.replace("- Single", "");
      } else if (ghroom_name.includes("- Double")) {
        blocknameadd = getRoomId[0]?.BlockName + " - " + ghroom_name.replace("- Double", "");
      } else {
        blocknameadd = ghroom_name;
      }

      let checkouttimebed =
        checkout[1].includes("AM") === true
          ? ((parseInt(checkoutampm) === 12 ? "00" : checkoutampm) + ":" + checkout[1])
              .replace("AM", ":00")
              .replace(" ", "")
          : (
              (parseInt(checkoutampm) === 12 ? parseInt(checkoutampm) : parseInt(checkoutampm) + 12) +
              ":" +
              checkout[1].replace("PM", ":00")
            ).replace(" ", "");

      let data = {
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        CheckInDate: s_dates[2] + "-" + s_dates[1] + "-" + s_dates[0],
        CheckInTime: document.querySelector("#checkintime").value || startTime,
        //CheckOutTime:document.querySelector("#checkouttime")?.value || outtimeampm !== undefined ? outtimeampm : "11:59 AM",
        CheckOutTime:
          document.querySelector("#EventType").value === "Room"
            ? checkoutampm + ":" + checkout[1] || "11:59 AM"
            : checkoutampm + ":" + checkout[1] || "11:59 AM",

        //CheckOutTime: document.querySelector("#EventType").value === "Room" ? checkoutampm + ":" + checkout[1] : GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? checkouttimebed : "11:59:00",

        // GH_property_ClientName[0][1][0].CheckIn24Hrs === true
        //   ? outtimeampm !== undefined
        //     ? outtimeampm
        //     : ""
        //   : "11:59 AM" || endTime,
        CheckOutDate: e_dates[2] + "-" + e_dates[1] + "-" + e_dates[0],
        SingleCount:
          document.querySelector("#EventType").value === "Room"
            ? add_tr_list?.length === 1
              ? 1
              : 0
            : add_tr_list?.length === 1
            ? 1
            : 2,
        DoubleCount: document.querySelector("#EventType").value === "Room" ? (add_tr_list?.length > 1 ? 1 : 0) : 0,
        TRId: !isNaN(TRrequestid) === true ? TRrequestid : "0",
        Client_Request_No: ClientRequest,
        BookerName:
          new_bookingdata_details[3][0]?.BookerName !== undefined ? new_bookingdata_details[3][0]?.BookerName : "",
        BookerEmail: new_bookingdata_details[3][0]?.Email !== undefined ? new_bookingdata_details[3][0]?.Email : "",
        BookerId: new_bookingdata_details[3][0]?.Id !== undefined ? new_bookingdata_details[3][0]?.Id : "",
        PropertyId:
          (property_details[0]?.PropertyId !== undefined && property_details[0]?.PropertyId) ||
          (property_details[0]?.Id !== undefined && property_details[0]?.Id) ||
          (`${propertyID[0]}` !== undefined && `${propertyID[0]}`),
        RoomName: document.querySelector("#EventType").value === "Room" ? blocknameadd.trim() : ghroom_name,
        //RoomName: document.querySelector("#EventType").value === "Room" ? getroomgroupnamedetails[0]?.text : ghroom_name,
        RoomId: ghroom_id,
        UserId: JSON.parse(localStorage.getItem("accessToken"))?.Id || JSON.parse(User_Details)?.Id,
        Occupancy: Occupancy?.value || occupancy,
        GuestDetails: [...guestcapsletter],
        ExtraCCEmail: document.querySelector("#ExtraCCEmail")?.value,
      };

      // data for booking ( blocked )
      let checkinflag = ClientFlagDetails;
      let booking_type = document.querySelector("#EventType");
      let trcount = document.querySelectorAll(".trcount");
      let ExtraCCEmail = document.querySelectorAll("#ExtraCCEmail");
      let s_date = document.querySelector("#STime")?.value.split("/");
      let e_date = document.querySelector("#ETime")?.value.split("/");

      let gridData = {
        PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
        FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
        ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
        PropertyName:
          property_details[0]?.PropertyName ||
          property_details[0]?.Property ||
          document.querySelector("#ghproperty")?.value,
        ss: "3",
      };
      if (
        e_EmpCode !== "" ||
        e_FName !== "" ||
        e_LName !== "" ||
        e_tEmail !== "" ||
        e_tMobileNo !== "" ||
        e_Grade !== "" ||
        e_Designation !== ""
      ) {
        setguesterrormsg(["Add guest details to the list"]);
        setmsg_option("Error");
        setTimeout(() => {
          error_closedateupdate();
        }, 2000);
      } else {
        if (emp_code.current.value !== "") {
          setguesterrormsg(["Add guest details to the list"]);
          setmsg_option("Error");
          setTimeout(() => {
            error_closedateupdate();
          }, 2000);
        } else {
          // more email validation check start
          let validmailmap = [];
          let mailmap = ExtraCCEmail[0]?.value?.split(",");
          validmailmap = mailmap?.map((item) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item));

          // more email validation check end
          if (
            (ExtraCCEmail[0]?.value !== "" &&
              // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ExtraCCEmail[0]?.value)) ||
              validmailmap.includes(false)) ||
            (document.querySelector("#EventType").value === "Room" &&
              document.querySelector("#Occupancy").value === "Single" &&
              add_tr_list?.length > 1)
          ) {
            if (
              ExtraCCEmail[0]?.value !== "" &&
              // !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ExtraCCEmail[0]?.value)
              validmailmap.includes(false)
            ) {
              setguesterrormsg((pre) => [...pre, " Please enter valid cc email"]);
              setmsg_option("Error");
            }
            if (
              document.querySelector("#EventType").value === "Room" &&
              document.querySelector("#Occupancy").value === "Single" &&
              add_tr_list?.length > 1
            ) {
              setguesterrormsg((pre) => [...pre, " Only 1 guest allowed for single occupancy"]);
              setmsg_option("Error");
            }
            // if ((parseInt(timediff) < 4 === true && GH_property_ClientName[0][1][0].CheckIn24Hrs === true)) {
            //   setguesterrormsg(["Minimum 4 hours required for booking"]);
            //   setmsg_option("Error");
            // }
            setTimeout(() => {
              error_closedateupdate();
              setguesterrormsg([]);
              setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
            }, 2000);
          } else {
            //check 24hrs flag for same day booking

            if (roomAvailable === "Available") {
              if (parseInt(timediff) < 4 === true && GH_property_ClientName[0][1][0].CheckIn24Hrs === true) {
                // setguesterrormsg(["Minimum 4 hours required for booking"]);
                setTimeout(() => {
                  error_closedateupdate();
                  setguesterrormsg([]);
                  // 24 hrs enabled for bed booking two loaders
                  isLoadingfunc(false);
                  setloadingspinner(false);
                  setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
                }, 2000);

              } else {
                let stimechange = document.querySelector("#StartTime")?.value;
                let etimechange = document.querySelector("#EndTime")?.value;
                if (GH_property_ClientName[0][1][0].CheckIn24Hrs === false && stimechange === etimechange) {
                  set_Error_Message_func(["Same date booking not allowed"]);
                  setTimeout(() => {
                    set_Error_Message_func([]);
                  }, 2000);

                } else {

                  if (booking_type.value === "Room" && editingIndex == null) {
                    if (trcount.length > 0 && trcount.length <= parseInt(allowed_guest.current.value)) {
                      isLoadingfunc(true);

                      axios
                        .post(`${proxy_Url}/RoomLevelBooking`, data, { headers: headers })
                        .then((res) => {
                          //remove
                          showNewBookingfunc(false);
                          setgender([]);
                          if (res.data.Result && res.data.Result === "Booked") {
                            set_Error_Message_func([
                              `Booking confirmed successfully, confirmation no : ${res.data.BookingCode}`,
                            ]);

                            setTimeout(() => {
                              set_Error_Message_func([]);
                            }, 5000);
                            setGuestvalueselect([]);
                          } else if (res.data?.data && res.data?.data !== "") {
                            set_Error_Message_func([res.data?.data]);
                            setTimeout(() => {
                              set_Error_Message_func([]);
                            }, 2000);
                            setGuestvalueselect([]);
                          } else {
                            set_Error_Message_func([res.data.Result]);
                            setTimeout(() => {
                              set_Error_Message_func([]);
                            }, 2000);
                            setGuestvalueselect([]);
                          }

                          setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
                          //grid data

                          gridoccupencydatafunc(gridData);
                          //clear the trselect datas
                          getTrSelectDetails({});
                          // clear tr details
                          setAdd_tr_list([]);
                          // error msg
                          isLoadingfunc(false);
                          close();
                          setroomAvailable("");

                          //change log error msg
                          setchangelog([]);
                          setshowchangelog(false);
                          //close booking template
                          showNewBookingfunc(false);
                          settimediff(5);
                        })
                        .catch((error) => {
                          setgetErrorMessagefunc(["Booking was not successful"]);
                          setTimeout(() => {
                            setgetErrorMessagefunc([]);
                          }, 2000);
                          // set_Error_Message_func(["Booking was not successful"])
                          setmsg_option("Error");

                          close();
                          isLoadingfunc(false);
                          setroomAvailable("");

                          //change log error msg
                          setchangelog([]);
                          setshowchangelog(false);
                        });
                      //problem showNewBookingfunc(!shownewbooking);
                    } else {
                      if (trcount.length > 0 && trcount.length > parseInt(allowed_guest.current.value)) {
                        if (parseInt(allowed_guest.current.value) === 1) {
                          setguesterrormsg([`Only 1 guest allowed for single occupancy`]);
                        } else {
                          setguesterrormsg([`Only 2 guest allowed for double occupancy`]);
                        }
                      } else {
                        setguesterrormsg(["Add guest details"]);
                      }

                      setTimeout(() => {
                        error_closedateupdate();
                      }, 2000);
                      setmsg_option("Alert");
                    }
                  } else if (booking_type.value === "Bed" && editingIndex == null) {
                    let stimechange = document.querySelector("#StartTime")?.value;
                    let etimechange = document.querySelector("#EndTime")?.value;

                    // same day booking if (stimechange !== etimechange) {
                    if (trcount.length > 0 && trcount.length <= parseInt(allowed_guest.current.value)) {
                      isLoadingfunc(true);
                      let addbedcheckouttime = {
                        ...data,
                        Bedcheckouttime:
                          GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? checkouttimebed : "11:59:00",
                      };
                      //if (gender[0] === data.GuestDetails?.[0].Title) {
                      bedbooking(data, gridData);
                      // } else {
                      //   setbedbookinggendershow(1);
                      // setGenderbched(0);
                      //  setbedbookingdatasend({ data: data, gridData: gridData });
                      // }

                      //
                      //problem  showNewBookingfunc(!shownewbooking);
                    } else {
                      if (trcount.length > 0 && trcount.length > parseInt(allowed_guest.current.value)) {
                        if (parseInt(allowed_guest.current.value) === 1) {
                          setguesterrormsg([`Only 1 guest allowed for single occupancy`]);
                        } else {
                          setguesterrormsg([`Only 2 guest allowed for double occupancy`]);
                        }
                      } else {
                        setgetErrorMessagefunc(["Add guest detail"]);
                      }

                      setmsg_option("Alert");
                      setTimeout(() => {
                        error_closedateupdate();
                      }, 2000);
                    }
                    // same day booking } else {
                    // same day booking   setgetErrorMessagefunc(["Same date booking not allowed"]);
                    // same day booking   setmsg_option("Alert");
                    // same day booking   setTimeout(() => {
                    // same day booking     error_closedateupdate();
                    // same day booking   }, 2000);
                    // same day booking }
                  } else if (booking_type.value === "Blocked" && editingIndex == null) {
                    let blk_cmt = document.querySelector("#blockroom");
                    if (blk_cmt?.value === "") {
                      set_Error_Message_func(["Comments is required"]);
                      setTimeout(() => {
                        set_Error_Message_func([]);
                        isLoadingfunc(false);
                        setloadingspinner(false);
                      }, 2000);
                      setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
                    } else {
                      isLoadingfunc(true);
                      let block_data = {
                        PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || `${propertyID[0]}`,
                        RoomId: ghroom_id,
                        FromDt: s_dates[0] + "/" + s_dates[1] + "/" + s_dates[2],
                        ToDt: e_dates[0] + "/" + e_dates[1] + "/" + e_dates[2],
                        FromTime: document.querySelector("#checkintime").value || startTime,
                        ToTime: checkoutampm + ":" + checkout[1] || "11:59 AM",
                        CheckIn24Hrs: checkinflag[0].CheckIn24Hrs === false ? 0 : 1,
                        UserId: JSON.parse(User_Details)?.Id,
                        Comments: document.querySelector("#blockroom")?.value || "Room is not Clean",
                      };

                      axios
                        .post(`${proxy_Url}/BlockInsertDetails`, block_data, { headers: headers })
                        .then((res) => {
                          if (res.Result !== "Room was not available") {
                          }
                          //clear the trselect datas
                          getTrSelectDetails({});
                          setgender([]);
                          gridoccupencydatafunc(gridData);
                          setGuestvalueselect([]);
                          // error msg
                          // setgetErrorMessagefunc(["Room blocked successfully"]);
                          set_Error_Message_func(["Room blocked successfully"]);
                          setTimeout(() => {
                            set_Error_Message_func([]);
                          }, 2000);
                          setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
                          setmsg_option("Success");
                          close();
                          // clear tr details
                          setAdd_tr_list([]);
                          isLoadingfunc(false);
                          setroomAvailable("");

                          //change log error msg
                          setchangelog([]);
                          setshowchangelog(false);
                          //close booking template
                          showNewBookingfunc(false);
                        })
                        .catch((error) => {
                          setgetErrorMessagefunc(["Booking was not successful"]);
                          setTimeout(() => {
                            setgetErrorMessagefunc([]);
                          }, 2000);
                          setmsg_option("Error");
                          close();
                          isLoadingfunc(false);
                          setroomAvailable("");

                          //change log error msg
                          setchangelog([]);
                          setshowchangelog(false);
                        });
                    }
                    //problem  showNewBookingfunc(!shownewbooking);
                  } else {
                    if (editingIndex != null) {
                      set_Error_Message_func(["Please save the guest details."]);
                      setErrorMsgClassfunc({ class: "error-warning", msg: "Error" });
                      setTimeout(() => {
                        set_Error_Message_func([]);
                      }, 2000);
                    }
                  }
                }
              }
              //timediff end
            } else {
              setnoroomavailable(["Room was not available"]);
              setmsg_option("Error");
              setTimeout(() => {
                setnoroomavailable([]);
                isLoadingfunc(false);
                setloadingspinner(false);
              }, 2000);
            }
          }
          //check 24hrs flag for same day booking
          //problem showNewBookingfunc(!shownewbooking);
        }
      }
    } else {
      setchkinout([]);
      if (cintime === "") {
        setchkinout([`Select Check-In time`]);
      }
      if (GH_property_ClientName[0][1][0].CheckIn24Hrs === true && couttime === "") {
        setchkinout((pre) => [...pre, `Select Check-Out time`]);
      }
      /*if(coutdate === ''){
      setnoroomavailable(pre => [...pre,"Invalid Check-Out date"]);
    }*/

      setmsg_option("Error");
      setTimeout(() => {
        setchkinout([]);
        isLoadingfunc(false);
        setloadingspinner(false);
      }, 2000);
    }
  };

  const malefemaleclose = () => {
    setmalefemale([]);

    if (document.querySelector("#EventType").value === "Bed" && gender?.length > 0) {
      ClearBooking();
    }
  };
  //close datepicker
  const error_close_day = () => {
    //show_error_templatefunc(false);
    errorAcceptButtonfunc(false);
    // showNewBookingfunc(!shownewbooking);
    setgetErrorMessagefunc([]);
    errorbuttonvalidationmsg(false);
    editorcancelenddatefunc(false);
    staydaystodisplayfunc("1");
    setgetstaydaysexceedfunc([]);
    setdatecount((pre) => pre + 1);

    //
    var date = new Date(getNew_bookingPopupData?.StartTime);
    date.setDate(date.getDate() + 1);
    setEditorInput((pre) => ({ ...pre, chkindate: getNew_bookingPopupData?.StartTime, chkoutdate: new Date(date) }));
    document.querySelector("#StartTime").value = moment(getNew_bookingPopupData?.StartTime).format("DD/MM/YYYY");
    document.querySelector("#EndTime").value = moment(date).format("DD/MM/YYYY");
    checkroomavail("Room");

    ClearBooking();
  };

  const error_close_noreset = () => {
    setguesterrormsg([]);
    errorAcceptButtonfunc(false);
    setgetErrorMessagefunc([]);
    errorbuttonvalidationmsg(false);
    editorcancelenddatefunc(false);
  };

  const error_close = () => {
    setguesterrormsg([]);
    errorAcceptButtonfunc(false);
    setgetErrorMessagefunc([]);
    errorbuttonvalidationmsg(false);
    editorcancelenddatefunc(false);
    var date = new Date(getNew_bookingPopupData?.StartTime);

    // Add a day
    date.setDate(date.getDate() + 1);
    setEditorInput((pre) => ({ ...pre, chkoutdate: new Date(date) }));
  };
  const error_closedateupdate = () => {
    setguesterrormsg([]);
    errorAcceptButtonfunc(false);
    setgetErrorMessagefunc([]);
    errorbuttonvalidationmsg(false);
    editorcancelenddatefunc(false);
  };
  const confirm_msg = () => {
    errorAcceptButtonfunc(false);
    setmalefemale_but(false);
    setgetErrorMessagefunc([]);
    errorbuttonvalidationmsg(true);
    staydaystodisplayfunc(staydayscount);
    editorcancelenddatefunc(true);
    setgetstaydaysexceedfunc([]);
  };

  const confirm_guest = () => {
    setmalefemale([]);
    if (
      new_booking_data[2][0]?.Column1 !== "" ||
      new_booking_data[2][0]?.Column2 !== "" ||
      new_booking_data[2][0]?.Column3 !== "" ||
      new_booking_data[2][0]?.Column4 !== "" ||
      new_booking_data[2][0]?.Column5 !== "" ||
      new_booking_data[2][0]?.Column6 !== "" ||
      new_booking_data[2][0]?.Column7 !== "" ||
      new_booking_data[2][0]?.Column8 !== "" ||
      new_booking_data[2][0]?.Column9 !== "" ||
      new_booking_data[2][0]?.Column10 !== ""
    ) {
      setNew_C_Fields(true);
    } else {
      let EmpCode = document.querySelector("#EmpCode")?.value.trim();
      let Title = document.querySelector("#BookingLevel")?.value.trim();
      let FName = document.querySelector("#FName");
      let LName = document.querySelector("#LName");
      let tEmail = document.querySelector("#tEmail");
      let tMobileNo = document.querySelector("#tMobileNo");
      let trcount = document.querySelectorAll(".trcount");
      let Grade = document.querySelector("#Grade")?.value.trim();
      let Designation = document.querySelector("#Designation")?.value.trim();
      let clienid = clientfulldetails[0][2].filter((item) => item.Grade === Grade);

      setAdd_tr_list([
        ...add_tr_list,
        {
          EmpCode: EmpCode,
          Title: Title,
          FirstName: FName?.value,
          LastName: LName?.value,
          Grade: Grade,
          Designation: Designation,
          EmailId: tEmail?.value,
          MobileNo: tMobileNo?.value,
          Column1: "",
          Column2: "",
          Column3: "",
          Column4: "",
          Column5: "",
          Column6: "",
          Column7: "",
          Column8: "",
          Column9: "",
          Column10: "",
          GradeId: clienid[0]?.Id,
          EmployeeId: "0",
        },
      ]);
      ClearDataFields();
    }
    setgetErrorMessagefunc([]);
    errorAcceptButtonfunc(false);
    setmalefemale_but(false);
  };

  const timeformatcheck = (value) => {
    const [time, modifier] = value.split(" ");

    let [hours, minutes] = time.split(":");

    if (parseInt(hours) === 12 && modifier === "AM") {
      hours = 0;
    } else if (parseInt(hours) < 12 && modifier === "AM") {
      hours = parseInt(hours);
    } else if (parseInt(hours) === 12 && modifier === "PM") {
      hours = parseInt(hours);
    } else {
      hours = parseInt(hours) + 12;
    }

    return hours;
    //return `${hours}:${minutes}`;
  };

  const onTimeChange = (args) => {
    //4 hrs time set

    setfourhrs();

    settime_changefunc(args.value);
    //can remove
    let intime = document.querySelector("#checkintime")?.value;
    let outtime = document.querySelector("#checkouttime")?.value;

    setmsg_option("Error");
    setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);

    const pattern = /\b(0?[1-9]|1[0-2]):00 (AM|PM)\b/;
    if (pattern.test(intime)) {
      timecheck({
        intime: timeformatcheck(intime),
        outtime: timeformatcheck(outtime),
      });
      if (document.querySelector("#EventType").value === "Bed") {
        checkroomavailbed();
        genderfunc();
      } else {
        checkroomavail("Room");
      }
    } else {
      let n_date = new Date();
      n_date.setHours(12, 0);
      settime_changefunc(n_date);
    }

    // if (time_change === time_changeout && time_changeout )  {
    //   settime_changefunc(time_change);
    // } else {
    //   settime_changefunc("12:00 PM");

    //   settime_changeoutfunc(new Date(time_change.setHours(time_change.getHours() + 4)));
    // }
  };
  const outTimeChange = (args) => {
    settime_changeoutfunc(args.value);
    let intime = document.querySelector("#checkintime")?.value;
    let outtime = document.querySelector("#checkouttime")?.value;
    const pattern = /\b(0?[1-9]|1[0-2]):00 (AM|PM)\b/;
    if (pattern.test(outtime)) {
      timecheck({
        intime: timeformatcheck(intime),
        outtime: timeformatcheck(outtime),
      });
      if (document.querySelector("#EventType").value === "Bed") {
        checkroomavailbed();
        genderfunc();
      } else {
        checkroomavail("Room");
      }
    } else {
      let n_date = new Date();
      n_date.setHours(11, 59);
      settime_changeoutfunc(n_date);
    }

    setmsg_option("Error");
    setchangelog(["Changes that you made may not be saved", "do you want to continue?"]);
  };

  const timecheck = (args) => {
    let stimechange = document.querySelector("#StartTime")?.value;
    let etimechange = document.querySelector("#EndTime")?.value;
    let chntime = document.querySelector("#checkintime")?.value;
    let ottime = document.querySelector("#checkouttime")?.value;
    settimediff(5);

    if (stimechange === etimechange) {
      let diff = args?.outtime - args?.intime;

      if (GH_property_ClientName[0][1][0].CheckIn24Hrs === true) {
        if (diff < 4) {
          // setguesterrormsg(["Minimum 4 hours required for booking"]);
          setTimeout(() => {
            setguesterrormsg([]);
          }, 2000);
        }
        // removed when time is set to default 4 hrs settimediff(diff);
      }

      //
    }
  };

  const confirm_changlog = () => {
    setchangelog([]);
    showNewBookingfunc(false);
  };
  const cancel_changlog = () => {
    setshowchangelog(false);
    // var date = new Date(getNew_bookingPopupData?.StartTime);

    // // Add a day
    // date.setDate(date.getDate() + 1);
    // setEditorInput((pre) => ({ ...pre, chkoutdate: date }));
  };

  const genderfunc = () => {
    bedbookinggendercheck();
  };

  //start date focus

  const checkindatefunc = (e) => {
    //4 hrs time set
    setfourhrs();
    setStartDate(e.target.value);
    // var Select_start_date = new Date(ss);
    //             Select_start_date.setDate(Select_start_date.getDate() + 1)
    let enddate = new Date(new Date(e.target.value).setDate(new Date(e.target.value).getDate() + 1));

    setEditorInput((pre) => ({
      ...pre,
      chkindate: e.target.value,
      chkoutdate: enddate,
    }));

    if (document.querySelector("#EventType").value === "Bed") {
      checkroomavailbed();

      // bed gender check
      genderfunc();

      ////////////////////////
    } else {
      checkroomavail("Room");
    }
  };
  const startonFocus = (args) => {
    Sdatref.show();
    document.querySelector("#StartTime").blur();
  };
  const setEndDate = (values) => {
    // gridstartenddate({ ...gridstartend_date, ToDte: values });
  };
  const EndonFocus = (args) => {
    Edatref.show();
    document.querySelector("#EndTime").blur();
  };

  const starttimeFocus = (args) => {
    intimeobj && intimeobj?.show();
    // document.querySelector("#checkintime").blur();
  };
  const endtimeFocus = (args) => {
    outdateObj && outdateObj?.show();
    // document.querySelector("#checkouttime").blur();
  };

  const disablekeyboard = () => {
    document.onkeydown = function (e) {
      return false;
    };
  };

  const enablekeyboard = () => {
    document.onkeydown = function (e) {
      return true;
    };
  };

  /*const specialCharPattern = /^[^a-zA-Z0-9]/;
  const disablespecialletter =  (event) => {
    const { value } = event.target;

    if (value === '' || !specialCharPattern.test(value.charAt(0))) {
     // onChange(value);
    }
  };*/

  const propInput_specialchar = (e) => {
    const specialCharPattern = /^[^a-zA-Z0-9]/;

    if (specialCharPattern.test(e.target.value.charAt(0))) {
      e.target.value = e.target.value.substring(1);
    }

    // This part handles trimming spaces at the start and end of the string
    if (/^\s/g.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^\s/g, "");
    } else {
      e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, " ");
    }
  };

  const handleMouseLeave = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].blur();
    }
  };
  const handleBlur = (event) => {
   
    // Add your blur logic here
  };

  const handelChange_grade = (event, index) => {
    const val = event.target.value;
   
    const updatedList = add_tr_list.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          Grade: val,
        };
        return updatedItem;
      }
      return item;
    });
   
    setAdd_tr_list(updatedList);
  };

  const settrfunc = (value) => {
    
    setAdd_tr_list(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Prevent default to prevent form submission
      event.preventDefault();
      // Handle autocomplete selection
    }
  };
  return (
    <>
      {getErrorMessage?.length > 0 && (
        <div className={`error_message ${(errorAcceptButton || malefemale_but) === true && "staydaysblock"}`}>
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed1" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
              {/* <div className="close_but bg-light ss" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {[...new Set(getErrorMessage)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>

            {errorAcceptButton && (
              <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_msg}>
                Yes
              </button>
            )}
            {malefemale_but && (
              <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_guest}>
                Yes
              </button>
            )}

            {(errorAcceptButton || malefemale_but) === true && (
              <button className="btn btn-sm btn-warning mt-n1 mb-2  continue-but" onClick={error_close}>
                No
              </button>
            )}
          </div>
        </div>
      )}
      {duplicatecheck?.length > 0 && (
        <div className={`error_message`}>
          <div className="error_Message_content t-center" style={{ width: "430px" }}>
            <div className="error_MSg_Con exceed1" style={{ background: "#ff0000" }}>
              <span>{msg_option} Message</span>{" "}
              {/* <div className="close_but bg-light ss" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {[...new Set(duplicatecheck)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {guestnotallowed?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed1" style={{ background: "#ff0000" }}>
              <span>{msg_option} Message</span>{" "}
              {/* <div className="close_but bg-light ss" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content" style={{ borderBottom: "none !important" }}>
              {[...new Set(guestnotallowed)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {getstaydaysexceed?.length > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
              {/* <div className="close_but bg-light ff" onClick={error_close_day}>
                X
              </div> */}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {getstaydaysexceed?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
            {getErrorMessage !== "Duplicate entries not allowed" && (
              <>
                {errorAcceptButton && (
                  <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2 continue-but" onClick={confirm_msg}>
                    Yes
                  </button>
                )}
                {malefemale_but && (
                  <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but" onClick={confirm_guest}>
                    Yes
                  </button>
                )}
              </>
            )}
            <button className="btn btn-sm btn-warning mt-n1 mb-2  continue-but" onClick={error_close_day}>
              No
            </button>
          </div>
        </div>
      )}
      {malefemale?.length > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {malefemale?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>

            {malefemale_but && (
              <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but" onClick={confirm_guest}>
                Yes
              </button>
            )}

            <button className="btn btn-sm btn-warning mt-n1 mb-2  continue-but" onClick={malefemaleclose}>
              No
            </button>
          </div>
        </div>
      )}
      {genderbchked > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">
                <p>{displaygender} guest was staying in that room, do you want to continue..?</p>
              </p>
            </div>

            <button
              className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but"
              onClick={() => {
                setGenderbched(0);
                setgenderbooking(0);
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-sm btn-warning mt-n1 mb-2  continue-but"
              onClick={() => {
                setGenderbched(0);
                setgenderbooking(1);
                setAdd_tr_list([]);
                ClearBooking();
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
      {bedbookinggendershow > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">
                <p>{displaygender} guest was staying in that room, do you want to continue..?</p>
              </p>
            </div>

            <button
              className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but"
              onClick={() => {
                // bedbooking(bedbookingdatasend.data, bedbookingdatasend.gridData);
                setbedbookinggendershow(0);

                setgenderbooking(0);
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-sm btn-warning mt-n1 mb-2  continue-but"
              onClick={() => {
                setbedbookinggendershow(0);
                setgenderbooking(1);
                setAdd_tr_list([]);
                ClearBooking();
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
      {dupchko?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {dupchko?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* guest details error msg */}
      {guesterrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {[...new Set(guesterrormsg)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      {noroomavailable?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">{noroomavailable}</p>
            </div>
          </div>
        </div>
      )}
      {chkinout?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {chkinout.map((item) => (
                <p className="t-center">{item}</p>
              ))}
            </div>
          </div>
        </div>
      )}
      {changelog?.length > 0 && showchangelog === true && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed3" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              {changelog?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
            <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_changlog}>
              Yes
            </button>
            <button className="btn btn-sm btn-warning mt-n1 mb-2  continue-but" onClick={cancel_changlog}>
              No
            </button>
          </div>
        </div>
      )}
      {showedittr && (
        <EditTr
          setShowedittr={setShowedittr}
          edittrval={edittrval}
          editindex={editindex}
          settr={settrfunc}
          Add_tr_list={add_tr_list}
        />
      )}

      {new_C_Fields && (
        <NewBookingCustomFields
          show_tr_count={show_tr_count}
          setShow_Tr_count={setShow_Tr_count}
          getAdditionalValue={getAdditionalValue}
          gettr_fromstorage={gettr_fromstorage && gettr_fromstorage}
          new_C_Fields={new_C_Fields}
          setNew_C_Fields={setNew_C_Fields}
          add_userenteryGuestDetails={add_userenteryGuestDetails}
          //ClearBooking={ClearBooking}
          ClearDataFields={ClearDataFields}
          // setSelectedValue={setSelectedValue}
        />
      )}
      {ghaccessdata[0]?.BookingResFlg === true && (
        <div className="changeEditor">
          <div className="editorBody">
            <div className="header">
              <span>
                New Booking for{" "}
                {ghproperty_Name?.value && ghproperty_Entity?.value && getRoomId[0]?.text && (
                  <b>{`${ghproperty_Name?.value || ""} | ${ghproperty_Entity.value || ""} | ${
                    getRoomId[0]?.text || ""
                  }`}</b>
                )}{" "}
              </span>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={close}>
                X
              </span>
            </div>
            <div className="body">
              <div className="">
                <form onSubmit={tablesubmit} autoComplete="off" className="tr_list_table new_tr_booking">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="change_editor">
                        <div className="form-row">
                          <div className="form-group col-md-3 blocked_booking chkinnotallowed">
                            <label className="e-textlabel">
                              {/* TR Details */}
                              TR Request{" "}
                              <span onClick={trClick} className="ghlink ghselect font-weight-bold">
                                Choose
                              </span>
                            </label>
                            <input
                              type="text"
                              defaultValue={!isNaN(editorInput.TRrequestid) === true ? editorInput.TRrequestid : ""}
                              className="trnoselect form-control hide_emp"
                              id="tr_number"
                              placeholder="TR No"
                              readOnly
                            />
                          </div>

                          <div
                            className={`form-group col-md-3 chkinheight ${
                              tr_select_details[0]?.length === 0 &&
                              editorInput.TRrequestid?.length < 2 &&
                              "chkinnotallowed"
                            } hide_emp`}
                          >
                            <label htmlFor="Check_In_Date">Check-In Date</label>

                            {/* <DatePickerComponent
                              //id="datetimepicker"
                              format="dd/MM/yyyy"
                              id="StartTime"
                              data-name="StartTime"
                              ref={(date) => (startObj = date)}
                              placeholder="Select time only"
                              //ref={startObj}

                              fields={{ text: "StartTime", defaultValue: editorInput.chkindate }}
                              //value={props.StartTime || props.startTime}
                              value={editorInput.chkindate}
                              style={{ width: "100%" }}
                              className="e-datetimepicker e-field e-input checkin_datetime disable_field"
                              name="chkindate"
                              onChange={EventDateChange}
                              allowEdit={false}
                              step={60}
                              //  onClick={onFocussdate}
                              openOnFocus={true}
                            /> */}
                            <DatePickerComponent
                              id="StartTime"
                              data-name="StartTime"
                              min={current_Date}
                              format="dd/MM/yyyy"
                              className="e-field"
                              ref={(scope) => (Sdatref = scope)}
                              name="StartTime"
                              value={editorInput.chkindate}
                              placeholder="Start Date"
                              onChange={(e) => checkindatefunc(e)}
                              focus={startonFocus}
                            ></DatePickerComponent>
                          </div>
                          <div
                            className={`timedisplay form-group ${
                              GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? "col-md-2" : "col-md-3"
                            } checkindatetime hide_emp time_border`}
                          >
                            <label htmlFor="Check_In_Date">Check-In Time</label>

                            <TimePickerComponent
                              id="checkintime"
                              openOnFocus={true}
                              onClick={starttimeFocus}
                              onChange={onTimeChange}
                              min={"12:00 PM"}
                              //selected={"12:00 PM"  }
                              max={maxtime}
                              ref={(scope) => (intimeobj = scope)}
                              step={60}
                              value={time_change || "12:00 PM"}
                              placeholder="Select a Time"
                              //focus={starttimeFocus}

                              onFocus={disablekeyboard}
                              onBlur={enablekeyboard}
                            />
                          </div>

                          <div
                            className={`chkdtheight form-group ${
                              GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? "col-md-2" : "col-md-3"
                            } checkoutdatetime hide_emp`}
                          >
                            <label htmlFor="Check-Out_Date">Check-Out Date</label>

                            {/* <DatePickerComponent
                              id="EndTime"
                              data-name="EndTime"
                              //name="chkoutdate"
                              format="dd/MM/yyyy"
                              ref={(date) => (endObj = date)}
                              name="EndTime"
                              min={editorInput.chkindate}
                              value={new Date(editorInput.chkoutdate)}
                              placeholder="Select date only"
                              //selected={new Date(selectedDate)}
                              //onSelect={new Date(selectedDate)}
                              openOnFocus={true}
                              fields={{ text: "EndTime", defaultValue: editorInput.chkoutdate }}
                              //tripDate = {editorInput.chkoutdate || enddatereset}
                              onChange={(e) => EventoutDateChange(e)}
                              style={{ width: "100%" }}
                              className="e-datetimepicker"
                              step={60}
                              //allowEdit={false}
                            ></DatePickerComponent> */}

                            <DatePickerComponent
                              id="EndTime"
                              data-name="EndTime"
                              format="dd/MM/yyyy"
                              className="e-field"
                              ref={(scope) => (Edatref = scope)}
                              name="chkoutdate"
                              // min={
                              //   new Date(editorInput.chkindate) == "Invalid Date"
                              //     ? editorInput.chkindate
                              //     : new Date(editorInput.chkindate)
                              // }
                              min={
                                GH_property_ClientName[0][1][0]?.CheckIn24Hrs
                                  ? new Date(editorInput.chkindate) == "Invalid Date"
                                    ? editorInput.chkindate
                                    : new Date(editorInput.chkindate)
                                  : new Date(
                                      new Date(editorInput.chkindate).setDate(
                                        new Date(editorInput.chkindate).getDate() + 1
                                      )
                                    )
                              }
                              value={new Date(editorInput.chkoutdate)}
                              placeholder="End Date"
                              //   max={new Date(constenddate !== undefined ? constenddate : e_date)}
                              // onChange={(e) => setEndDate(e.target.value)}
                              onChange={(e) => EventoutDateChange(e)}
                              focus={EndonFocus}
                              openOnFocus={true}
                            ></DatePickerComponent>

                            {/* <DatePickerComponent
                              //id="datetimepicker"
                              format="dd/MM/yyyy"
                              id="EndTime"
                              data-name="EndTime"
                              ref={(date) => (endObj = date)}
                              placeholder="Select time only"
                              //ref={startObj}
                              fields={{ text: "EndTime", defaultValue: editorInput.chkoutdate }}
                              //value={props.StartTime || props.startTime}
                              value={editorInput.chkoutdate}
                              style={{ width: "100%" }}
                              className="e-datetimepicker e-field e-input checkin_datetime disable_field"
                              name="EndTime"
                              onChange={e => EventoutDateChange(e)}
                              allowEdit={false}
                              step={60}
                              //  onClick={onFocussdate}
                              openOnFocus={true} 
                            /> */}
                            {/* <DatePicker
    dateFormat="dd/MM/yyyy"
    value={Cdate}
    id="EndTime"
    onChange={(date) => {
      const d = new Date(date).toLocaleDateString('fr-FR');
    
      setDate(d);
     
    }}
  /> */}

                            {/* <div class="quesadilla">
                            <input type="date" className="date enchilada" value={`${dd || editorInput.chkoutdate}`} onchange={e => setdd(e.target.value)}/>
                            </div> */}
                            {/* <DatePicker selected={editorInput.chkoutdate} onChange={EventDateChange} /> */}
                            <div className="form-control-wrap"></div>
                          </div>
                          <div
                            className={`timedisplay form-group ${
                              GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? "col-md-2" : "d-none"
                            } checkindatetime hide_emp  time_border chkotdatetime`}
                          >
                            <label htmlFor="Check_In_Date">Check-Out Time</label>

                            <TimePickerComponent
                              id="checkouttime"
                              openOnFocus={true}
                              onClick={endtimeFocus}
                              min={changeouttime}
                              value={time_changeout || "11:59 AM"}
                              onChange={outTimeChange}
                              placeholder="Select a Time"
                              ref={(scope) => {
                                outdateObj = scope;
                              }}
                              //focus={endtimeFocus}
                              // openOnFocus={true}
                              // onfiltering={outfiltering}
                              step={60}
                              onFocus={disablekeyboard}
                              onBlur={enablekeyboard}
                            />
                          </div>
                          <div className="form-group col-md-3 blocked_booking">
                            <label htmlFor="ClientRequestNo">Client Request / Ref. No</label>
                            <input
                              type="text"
                              name="ClientRequest"
                              defaultValue={editorInput.ClientRequest}
                              className="trnoselect form-control hide_emp"
                              id="ClientRequestNo"
                              onChange={handelChange}
                              onInput={propInput}
                            />
                          </div>
                          <div className="form-group col-md-3 blocked_booking">
                            <label htmlFor="inputAddress">Extra CC Mail</label>
                            <input
                              name="ExtraccMail"
                              onChange={handelChange}
                              type="text"
                              defaultValue={editorInput.ExtraccMail}
                              className="trnoselect form-control hide_emp"
                              id="ExtraCCEmail"
                              onInput={propInput}
                            />
                          </div>

                          <div className="form-group col-md-2 hide_emp">
                            <label htmlFor="EventType">Booking Level</label>
                            <DropDownListComponent
                              //    ref={trbookref}
                              id="EventType"
                              placeholder="Choose Type"
                              data-name="EventType"
                              style={{ width: "100%" }}
                              dataSource={["Room", "Bed", "Blocked"]}
                              value={editorInput.BType || "Room"}
                              onChange={handelChange}
                              name="BType"
                              className="e-field e-input disble"
                            ></DropDownListComponent>
                          </div>
                          <div className="form-group col-md-12 blocked-comments" style={{ display: "none" }}>
                            <label htmlFor="MaxGuestAllowed">
                              Comments <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea
                              type="text"
                              name="blockroom"
                              defaultValue={editorInput.blockroom || ""}
                              className="form-control hide_emp"
                              id="blockroom"
                              onChange={handelChange}
                            ></textarea>
                          </div>
                          <div className="form-group col-md-2 occupency_disable blocked_booking">
                            <label htmlFor="Occupancy">Occupancy</label>

                            <DropDownListComponent
                              //    ref={trbookref}
                              id="Occupancy"
                              placeholder="Choose Type"
                              data-name="Occupancy"
                              style={{ width: "100%" }}
                              dataSource={["Single", "Double"]}
                              value={editorInput.occupancy || "Single"}
                              onChange={handelChange}
                              name="occupancy"
                              className="e-field e-input disble hide_emp"
                              disabled={true}
                            ></DropDownListComponent>
                          </div>
                          <div className="form-group col-md-2 blocked_booking chkinnotallowed">
                            <label htmlFor="MaxGuestAllowed">Max Guest Allowed</label>
                            <input
                              type="text"
                              name="guestAllowed"
                              defaultValue={editorInput.guestAllowed || 1}
                              className="trnoselect form-control hide_emp"
                              id="MaxGuestAllowed"
                              onChange={handelChange}
                              readOnly
                              ref={allowed_guest}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6 text-center">
                      <div className="bg-light py-1 mr-md-n2 mt-sm-1">
                        {" "}
                        Approximate Stay Days :{" "}
                        <b className="c-red">{staydaystodisplay === 0 ? 1 : staydaystodisplay}</b>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bg-light text-center py-1 ml-md-n2 mt-sm-1">
                        <b className="c-red" style={{ color: roomAvailable === "Available" && "green" }}>
                          {loadingspinner && <Spinner size="sm" color="light" className="loadingspinner" />}&nbsp;&nbsp;
                          {roomAvailable}
                        </b>
                      </div>
                    </div>
                  </div>
                  {showguestselect && guestselect?.length > 0 && (
                    <GuestListShow
                      guestselect={guestselect}
                      showguestselect={showguestselect}
                      geGguestselect={geGguestselect}
                      setShowGuestselect={setShowGuestselect}
                    />
                  )}
                  <div className="o_flow" style={{ overflow: "auto" }}>
                    <table role="table" className="tr_select blocked_booking" style={{ width: "100%" }}>
                      <thead role="rowgroup">
                        <tr role="row">
                          <th role="columnheader">Emp&nbsp;Code</th>
                          <th role="columnheader">Title</th>
                          <th role="columnheader">
                            First&nbsp;Name<span className="c-red">&nbsp;*</span>
                          </th>
                          <th role="columnheader">
                            Last&nbsp;Name<span className="c-red">&nbsp;*</span>
                          </th>
                          <th role="columnheader">Grade</th>
                          <th role="columnheader">Designation</th>
                          <th role="columnheader">
                            Email<span className="c-red">&nbsp;*</span>
                          </th>
                          <th role="columnheader">
                            Mobile&nbsp;No<span className="c-red">&nbsp;*</span>
                          </th>
                          <th role="columnheader" align="center" className="t_left" style={{ width: "100px" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody role="rowgroup">
                        <tr role="row">
                          <td role="cell" style={{ minWidth: "78px" }}>
                            <input
                              name="EmpCode"
                              id="EmpCode"
                              className="e-field e-input hide_emp"
                              placeholder="Emp Code"
                              data-name="EmpCode"
                              type="text/x-template"
                              style={{ width: "100%" }}
                              defaultValue={guest_editor.EmpCode}
                              onChange={handleChange1}
                              onInput={propInput}
                              ref={emp_code}
                            />
                          </td>
                          <td style={{ minWidth: "78px" }} role="cell" className="not-allowed">
                            <select
                              id="BookingLevel"
                              name="Title"
                              className="form-control hide_emp"
                              value={guest_editor.Title === "" ? "Select" : guest_editor.Title === "Mr" ? "Mr" : "Ms"}
                              onChange={g_handelChange}
                            >
                              <option value="Select">Select</option>
                              <option value="Mr">Mr</option>
                              <option value="Ms">Ms</option>
                              {/* <option value="Mrs">Mrs</option> */}
                            </select>
                          </td>
                          <td role="cell">
                            <input
                              id="FName"
                              className="e-field e-input hide_emp"
                              name="FirstName"
                              placeholder="First Name"
                              data-name="FirstName"
                              type="text/x-template"
                              style={{ width: "100%" }}
                              // fields={{ text: "FirstName", defaultValue: trInputstates.FirstName }}
                              value={guest_editor.FirstName}
                              onChange={g_handelChange}
                              ref={emp_fname}
                              onInput={propInput}
                            />
                          </td>
                          <td role="cell">
                            <input
                              id="LName"
                              className="e-field e-input hide_emp"
                              name="LastName"
                              placeholder="Last Name"
                              data-name="LastName"
                              type="text/x-template"
                              style={{ width: "100%" }}
                              //fields={{ text: "LastName", defaultValue: trInputstates.LastName }}
                              value={guest_editor.LastName}
                              onChange={g_handelChange}
                              ref={emp_lname}
                              onInput={propInput_specialchar}
                            />
                          </td>
                          <td role="cell" className="gradeblk">
                            {/* <input
                              id="Grade"
                              className="e-field e-input hide_emp"
                              name="Grade"
                              placeholder="Grade"
                              data-name="Grade"
                              type="text/x-template"
                              style={{ width: "100%" }}
                              // fields={{ text: "Text", defaultValue: trInputstates.Grade }}
                              value={guest_editor.Grade}
                              onChange={g_handelChange}
                              onInput={propInput}
                            />  */}
                            {/* <select
                              id="Grade"
                              name="Grade"
                              className="form-control hide_emp"
                              value={guest_editor.Grade === "" ? "Select" : guest_editor.Grade}
                              onChange={g_handelChange}
                            >
                              <option value="Select">Select</option>
                              <option value="A4asd">A4asd</option>
                              <option value="Ms">Ms</option>
                              
                </select>*/}
                            <span style={{ marginTop: "2px" }}>
                              <AutoCompleteComponent
                                id="Grade"
                                name="Grade"
                                value={guest_editor.Grade != 0 ? guest_editor.Grade : null}
                                placeholder="Grade"
                                className="form-control"
                                onChange={g_handelChange}
                                dataSource={client_f_detail}
                                onKeyDown={(e) => e.preventDefault()}

                                // filtering={gridonChange}
                                // onInput={fromhomepagepropertyfunc()}
                                // select={handleSelect}
                              />
                            </span>
                          </td>
                          <td role="cell">
                            <input
                              id="Designation"
                              className="e-field e-input hide_emp"
                              name="Designation"
                              placeholder="Designation"
                              data-name="Designation"
                              type="text/x-template"
                              style={{ width: "100%" }}
                              //  fields={{ text: "Designation", defaultValue: trInputstates.Designation }}
                              value={guest_editor.Designation}
                              onChange={g_handelChange}
                              onInput={propInput}
                            />
                          </td>
                          <td role="cell">
                            <input
                              id="tEmail"
                              className="e-field e-input hide_emp"
                              name="EmailId"
                              placeholder="Email"
                              data-name="Email"
                              type="text"
                              style={{ width: "100%" }}
                              fields={{ text: "Email", value: guest_editor.EmailId }}
                              value={guest_editor.EmailId}
                              onChange={g_handelChange}
                              ref={emp_email}
                              onInput={propInput}
                              required={false}
                            />
                          </td>
                          <td role="cell">
                            <input
                              id="tMobileNo"
                              className="e-field e-input hide_emp"
                              name="MobileNo"
                              placeholder="Mobile No"
                              data-name="MobileNo"
                              type="text"
                              style={{ width: "100%" }}
                              value={mobilenumber}
                              onChange={(e) => setmobilenumber(textNum(e))}
                              ref={emp_mobile}
                              maxLength="10"
                              onInput={propInput}
                            />
                          </td>
                          <td role="cell" style={{ padding: "6px 2px", minWidth: "100px" }}>
                            <button id="tradd" onClick={addAdditionalfields} className="btn btn-primary btn-xs ad-btn">
                              Add
                            </button>
                            <button onClick={ClearDataFields} className="btn btn-warning btn-xs ml-1 clrbtn">
                              Clear
                            </button>
                          </td>
                        </tr>

                        {add_tr_list &&
                          add_tr_list.map((item, index) => {
                            return (
                              <tr
                                className="trcount"
                                key={index}
                                role="cell"
                                style={{ background: "#fff", border: "1.1px solid #dc1501 !important" }}
                              >
                                <td role="cell">{item.EmpCode || item.Empcode || item.empCode} </td>
                                <td role="cell">{item.Title}</td>
                                <td role="cell">{item.FirstName}</td>
                                <td role="cell">{item.LastName}</td>
                                <td role="cell">{item.Grade != 0 && item.Grade}</td>
                                <td role="cell">{item.Designation}</td>
                                <td role="cell">{item.EmpEmail || item.Email || item.EmailId}</td>
                                <td role="cell">{item.EmpMobile || item.MobileNo}</td>
                                <td align="center" role="cell">
                                  <span className="editTr" onClick={() => handleEditClick(item, index)}>
                                    <GrEdit />
                                  </span>
                                  <span className="delTr" onClick={() => removeEmp(item, index)}>
                                    <FaRegTrashAlt />
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>

            <div className="footer">
              <input
                type="button"
                value={block === 1 ? "Block Room" : " Confirm Booking"}
                className="btn btn-success btn-sm mr-1 bookingconfirm"
                onClick={saveBooking}
              />
              <input
                type="button"
                value={block === 1 ? "Clear Room" : " Clear Booking"}
                className="btn btn-warning btn-sm mr-1"
                onClick={ClearBooking}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(NewBookingPopupTemplate);
