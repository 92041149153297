export default (state, action) => {
  switch (action.type) {
    // login details
    case "GET_LOGIN_NAME":
      return {
        ...state,
        User_Details: [action.payload],
      };
    // client details
    case "GET_CLIENT_ID":
      return {
        ...state,
        clientID: [action.payload],
      };
    case "GET_CLIENT_Details":
      return {
        ...state,
        clientDetails: [action.payload],
      };
      case "GET_FULL_CLIENT_Details":
      return {
        ...state,
        clientfulldetails: [action.payload],
      };
    case "CLIENTDETAILS":
      return {
        ...state,
        GH_property_ClientName: [action.payload],
      };
    case "GET_CLIENTID":
      return {
        ...state,
        client_id: action.payload,
      };
      case "GET_HOME_PAGE_PROPERTYFUNC":
      return {
        ...state,
        fromhomepagepropertydata: action.payload,
      };
    case "GET_CLIENT_REQUEST":
      return {
        ...state,
        ClientRequest: action.payload,
      };
    case "GET_CLIENT_FLAG_DETAILS":
      return {
        ...state,
        ClientFlagDetails: action.payload,
      };

    //property load

    case "ADD_TO_PROPERTY_LOAD":
      return {
        ...state,
        propertyLoad: [action.payload, ...state.propertyLoad],
      };
    case "GET_PROPERTY_ID":
      return {
        ...state,
        propertyID: [action.payload],
      };
    case "PROPERTY_DETAILS":
      return {
        ...state,
        property_details: [action.payload],
      };
    case "GET_PROP_NAME_ID":
      return {
        ...state,
        propnameid: action.payload,
      };

    // tr data details

    case "GET_Emp_FROM_TR":
      return {
        ...state,
        getempdetfTR: [action.payload],
      };

    case "GET_TR_SELECT_DATA":
      return {
        ...state,
        tr_select_details: [action.payload],
      };
    case "LOAD_TR_DETAILS":
      return {
        ...state,
        tr_details: [action.payload],
      };
      case "ROOM_SHIFT_DATA":
        return {
          ...state,
          roomshiftdata: action.payload,
        };
    case "SHOW_TR":
      return {
        ...state,
        showTr: action.payload,
      };
    //dashboard

    case "ADD_TO_BOOKING_DASHBOARD":
      return {
        ...state,
        dashboard: [action.payload],
      };
    case "GET_PROPERTY_OCCUPENCY":
      return {
        ...state,
        occupency: [action.payload, ...state.occupency],
      };
    case "OCCUPENCY_DATA":
      return {
        ...state,
        occupency_Data: [action.payload],
      };
    case "SET_OCCUPENCY":
      return {
        ...state,
        set_occupency: action.payload,
      };
    //scheduler
    case "ADD_TO_SCHEDULER":
      return {
        ...state,
        scheduler: [action.payload, ...state.scheduler],
      };

    case "GRID_DATA":
      return {
        ...state,
        scheduler_Data: [action.payload],
      };
    case "SCHEDULE_DATA_CONST":
      return {
        ...state,
        scheduledataoriginal: [action.payload],
      };
    //gh details

    case "GET_GH_NAME":
      return {
        ...state,
        ghName: [action.payload],
      };
    case "SHOW_GH":
      return {
        ...state,
        showGh: action.payload,
      };
    case "SET_SHOW_GH":
      return {
        ...state,
        showGhname: action.payload,
      };
    case "GET_GH_ROOM_NAME":
      return {
        ...state,
        ghroom_name: action.payload,
      };
    case "GET_GH_ROOM_ID":
      return {
        ...state,
        ghroom_id: action.payload,
      };
    case "GET_ROOM_ID":
      return {
        ...state,
        room_id: action.payload,
      };
    case "GET_GROUP_ID":
      return {
        ...state,
        group_id: action.payload,
      };

    //bookingdata
    // repeat data name - clientDetails
    case "GET_Booking_Data":
      return {
        ...state,
        clientDetails: [action.payload],
      };
    case "GH_ACCESS_DATA":
      return {
        ...state,
        ghaccessdata: [action.payload],
      };
    case "NEWBOOKINGDATA":
      return {
        ...state,
        newbooking_data: action.payload,
      };
    case "SHOW_NEW_BOOKING":
      return {
        ...state,
        shownewbooking: action.payload,
      };
    // table data
    // no function
    case "TABLE_DATA":
      return {
        ...state,
        additionalfields: [action.payload],
      };

    // additional data
    case "ADDITIONAL_DATA":
      return {
        ...state,
        additionalfields: [action.payload],
      };

    // error data

    case "SHOW_ERROR_TEMPLATE":
      return {
        ...state,
        showerror_template: action.payload,
      };
    case "GET_ERROR_MSG":
      return {
        ...state,
        err_msg: [...state.err_msg, action.payload],
      };
    case "SET_ERROR_MSG_FUNC":
      return {
        ...state,
        set_err_msg: action.payload,
      };
      case "SET_MODIFY_ERROR_MSG_FUNC":
      return {
        ...state,
        set_modify_err_msg: action.payload,
      };
      case "BLOCK_MODIFY_SUCCESS":
        return {
          ...state,
          modifysuccessMessage: action.payload,
        };
      case "CHANGE_ROOM_ID":
      return {
        ...state,
        changeroomid: action.payload,
      };
    case "ERROR_ACCEPT_BUTTON":
      return {
        ...state,
        errorAcceptButton: [action.payload],
      };
      case "GET_BLOCK_MODIFY_DATA":
        return {
          ...state,
          blockmodify: action.payload,
        };
        case "GET_BLOCK_HISTORY_DATA":
          return {
            ...state,
            blockhistory: action.payload,
          };
    case "ERROR_BUTTON_VALIDATION":
      return {
        ...state,
        errorvalidationButton: [action.payload],
      };
      case "SET_TEST_DATE":
      return {
        ...state,
        testdate: action.payload,
      };
      case "MODIFY_CHECK_DATA":
      return {
        ...state,
        editmodicheck: action.payload,
      };
    case "GET_CLASS_NAME":
      return {
        ...state,
        error_class_name: [action.payload],
      };
    case "ERROR_MSG_COLOR":
      return {
        ...state,
        error_msg_color: [action.payload],
      };
    case "LOAD_GRID_SPINNER":
      return {
        ...state,
        enablespinner: [action.payload],
      };
    case "IS_LOADING":
      return {
        ...state,
        is_loading: action.payload,
      };

    // date functions

    case "SET_START_DATE":
      return {
        ...state,
        start_date: action.payload,
      };
    case "SET_END_DATE":
      return {
        ...state,
        end_date: action.payload,
      };
    case "SET_START_TIME":
      return {
        ...state,
        start_time: action.payload,
      };
    case "SET_END_TIME":
      return {
        ...state,
        end_time: action.payload,
      };
    case "GRID_START_END_DATE":
      return {
        ...state,
        gridstartend_date: action.payload,
      };
    case "CONST_END_DATE":
      return {
        ...state,
        constenddate: action.payload,
      };
    case "STAY_DAYS_COUNT":
      return {
        ...state,
        staydayscount: action.payload,
      };
    case "STAY_DAYS_COUNT_DISPLAY":
      return {
        ...state,
        staydaystodisplay: action.payload,
      };

    case "NEWDATE":
      return {
        ...state,
        newdate: action.payload,
      };
    case "EDITORENDDATEFUNC":
      return {
        ...state,
        editorcancelenddate: action.payload,
      };

    //event

    case "SET_CANCEL_EVENT":
      return {
        ...state,
        cancel_event: action.payload,
      };
    // case "BOOKING_CANCEL_DETAIL":
    //   return {
    //     ...state,
    //     bookingcanceldata: action.payload,
    //   };
    case "CHANGE_EVENT_DATA":
      return {
        ...state,
        change_event_data: action.payload,
      };

    //delete data
    case "DELETEDATA":
      return {
        ...state,
        delete_data: action.payload,
      };

    // edit/modification data
    case "EDIT_MODIFICATION_DATA":
      return {
        ...state,
        editmodificationdata: action.payload,
      };
    case "SET_CHANGE_DATA":
      return {
        ...state,
        staychangedate: action.payload,
      };
      case "STAY_SHIFT":
        return {
          ...state,
          stayshift: action.payload,
        };
        case "SHOW_INTRO":
          return {
            ...state,
            showinto: action.payload,
          };
    case "SET_CHANGE_EVENT_DATA":
      return {
        ...state,
        setstaychangedate: action.payload,
      };
    case "SET_EDITOR_DATA":
      return {
        ...state,
        seteditordata: action.payload,
      };
    case "SET_BOOKING_MODIFT_CANCEL_DATA":
      return {
        ...state,
        booking_modify_cancel_data: action.payload,
      };
    case "EDIT_DATA":
      return {
        ...state,
        edit_data: action.payload,
      };

    //new bookingdata
    case "NEW_BOOKING_POPUP_DATA":
      return {
        ...state,
        new_booking_popupdata: action.payload,
      };

    case "SET_NEW_BOOKING_DATA":
      return {
        ...state,
        new_booking_data: action.payload,
      };
      case "SET_INITIAL_DAY":
      return {
        ...state,
        initial_day: action.payload,
      };
    // case "BOOKING_MODIFY_CANCEL_DATA":
    // return {
    //   ...state,
    //   bookingmodificanceldata: action.payload,
    // };

    case "CLIENT_CUSTOM_FIELDS_COLLECTION":
      return {
        ...state,
        clientcustomfieldscollection: action.payload,
      };
    case "ERROR_MSG_CLASS":
      return {
        ...state,
        error_msg_class: action.payload,
      };
    case "BLOCK_DELETE_DATA":
      return {
        ...state,
        block_delete_data: action.payload,
      };
    case "SHOW_BLOCK_DELETE_DATA":
      return {
        ...state,
        showblockdelete: action.payload,
      };
    case "DAYS_COUNT":
      return {
        ...state,
        dayscount: action.payload,
      };
      case "GH_OCCUPANCYFLAG_DATA":
        return {
          ...state,
          Occupancycheckflag: action.payload,
        };

    default:
      return state;
  }
};
