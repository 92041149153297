import React, { createContext, useCallback, useReducer } from "react";
import Bookingreducer from "./BookingReducer";
import axios from "axios";

//create initialState
const initialState = {
  dashboard: [],
  scheduler: [],
  ghName: [],
  occupency: [],
  propertyID: [],
  propertyLoad: [],
  getempdetfTR: [],
  userid: 0,
  clientID: 0,
  clientDetails: [],
  bookingdata: [],
  User_Details: sessionStorage.getItem("credentials"),
  token:   localStorage.getItem("accessToken1")?.replaceAll(`"`, ``),

  //test link
 //  proxy_Url: "https://testnewghreportapi.staysimplyfied.com/api",

//demolink
 proxy_Url: "https://demoghreportapi.staysimplyfied.com/api",

//live link
   //proxy_Url: "https://newghapi.staysimplyfied.com/api",


  //localhost
  //proxy_Url: "http://localhost:3001/api",


  tr_select_details: [],
  showerror_template: false,
  err_msg: [],
  error_class_name: "",
  is_loading: false,
  property_details: [],
  GH_property_ClientName: [],
  scheduler_Data: { scheduleData: [], groupData: [], roomData: [] },
  occupency_Data: { scheduleData: [], groupData: [], roomData: [] },
  error_msg_color: {},
  enablespinner: false,
  //new select tr details
  tr_details: [],

  //data collection
  additionalfields: JSON.parse(localStorage.getItem("trInputstates")),
  //  newDatabooking: [],
  scheduledataoriginal: [],
  showGh: false,
  start_date: "",
  end_date: "",
  errorAcceptButton: false,
  errorvalidationButton: true,
  start_time: "",
  end_time: "",
  cancel_event: true,
  set_occupency: false,
  gridstartend_date: {},
  constenddate: "",
  propnameid: {},
  // bookingcanceldata: {},
  staydayscount: "",
  staydaystodisplay: "1",
  newdate: "",
  editorcancelenddate: "",
  newbooking_data: {},
  delete_data: [],
  showGhname: "",
  shownewbooking: false,
  showTr: false,
  change_event_data: [],
  editmodificationdata: [],
  staychangedate: {},
  seteditordata: {},
  booking_modify_cancel_data: [],
  new_booking_popupdata: {},
  new_booking_data: [],
  edit_data: {},
  ghroom_name: "",
  ghroom_id: "",
  room_id: 0,
  group_id: 0,
  client_id: "",
  // ClientRequest:"",
  ClientFlagDetails: [],
  ghaccessdata: [],
  // gh_accessdata: [
  //   {
  //     BookingResFlg: true,
  //     ModifyResFlg: true,
  //     CancelResFlg: true,
  //     CheckOutResFlg: false,
  //   },
  // ],
  set_err_msg: [],
  set_modify_err_msg: [],
  error_msg_class: {},
  //bookingmodificanceldata:[],
  clientcustomfieldscollection: [],
  setstaychangedate: [],
  block_delete_data: [],
  showblockdelete: false,
  dayscount: 31,
  stayshift:'',
  showinto:false,
  roomshiftdata:[],
  changeroomid:0,
  blockmodify:[],
  blockhistory:[],
  editmodicheck:[],
  testdate:null,
  fromhomepagepropertydata:[],
  modifysuccessMessage:[],
  initial_day:0,
  settime : 2000,
  Occupancycheckflag:false,
  clientfulldetails:[]
};

//create Context
export const BookingContent = createContext(initialState);
//provider components
export const BookingProvider = (props) => {
  const [state, dispatch] = useReducer(Bookingreducer, initialState);
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${state.token}`,
  };
  let ghuserId = JSON.parse(localStorage.getItem("accessToken")) && JSON.parse(localStorage.getItem("accessToken"))?.Id;

  //get guest house login auth
  const getLoginAuth = (value) => {
    dispatch({ type: "GET_LOGIN_NAME", payload: value });
    window.sessionStorage.setItem("accessToken", JSON.stringify(value));
  };
  //get empcode from tr select
  const ghClientID = (value) => {
    dispatch({ type: "GET_CLIENT_ID", payload: value });
  };
  const ghClientDetails = (value) => {
    dispatch({ type: "GET_CLIENT_Details", payload: value });
  };
  const ghclientfulldetails = (value) => {
    dispatch({ type: "GET_FULL_CLIENT_Details", payload: value });
  };
  const getGHClientName = (values) => {
    dispatch({ type: "CLIENTDETAILS", payload: values });
  };
  //getclientidfunc
  const getclientidfunc = (values) => {
    dispatch({ type: "GET_CLIENTID", payload: values });
  };
   //getclientidfunc
   const fromhomepagepropertyfunc = (values) => {
    dispatch({ type: "GET_HOME_PAGE_PROPERTYFUNC", payload: values });
  };

  // //getClientRequestfunc
  // const getClientRequestfunc = (values) => {
  //   dispatch({ type: "GET_CLIENT_REQUEST", payload: values });
  //
  // };

  //getClientFlagDetailsfunc
  const getClientFlagDetailsfunc = (values) => {
    dispatch({ type: "GET_CLIENT_FLAG_DETAILS", payload: values });
  };
  const setmodifysuccessMessage = (values) => {
    dispatch({ type: "BLOCK_MODIFY_SUCCESS", payload: values });
  };

   //modifyblockdata
   const getmodifyblockdata = (values) => {
    dispatch({ type: "GET_BLOCK_MODIFY_DATA", payload: values });
  };
  //modifyblockdata
  const getmodifyhistorydata = (values) => {
    dispatch({ type: "GET_BLOCK_HISTORY_DATA", payload: values });
  };

  const addToProperty = (values) => {
    //set session id
    sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
    sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
    let bodyData = {
      UserId: ghuserId,
    };

    axios
      .post(`${state.proxy_Url}/PropertyLoad`, bodyData)
      .then(({ data }) => {
        dispatch({ type: "ADD_TO_PROPERTY_LOAD", payload: data.Result[0] });
       
      })
      .catch((error) => {});
  };

  //get guest house PropertyId
  const getPropertyId = (value) => {
    dispatch({ type: "GET_PROPERTY_ID", payload: value });
  };

  // get property details
  const getPropertyDetails = (values) => {
   
    dispatch({ type: "PROPERTY_DETAILS", payload: values });
  };

  const getpropertynameandid = (values) => {
    dispatch({ type: "GET_PROP_NAME_ID", payload: values });
  };
  //get empcode from tr select
  const empcodeload = (value) => {
    dispatch({ type: "GET_Emp_FROM_TR", payload: value });
  };
  const getTrSelectDetails = (values) => {
    dispatch({ type: "GET_TR_SELECT_DATA", payload: values });
  };
  const settrdetailsfunc = (values) => {
    dispatch({ type: "LOAD_TR_DETAILS", payload: values });
  };
  //show new booking
  const setShowTrfunc = (values) => {
    dispatch({ type: "SHOW_TR", payload: values });
  };

  //get values from dashboard
  const addToDashboard = (values) => {
    dispatch({ type: "ADD_TO_BOOKING_DASHBOARD", payload: values });
  };

  //get guesthouse Occupency name
  const getGhOccupency = (values) => {
    dispatch({ type: "GET_PROPERTY_OCCUPENCY", payload: values });
  };
  const setOccupencyData = (values) => {
    dispatch({ type: "OCCUPENCY_DATA", payload: values });
  };
  const setghOccupency = (values) => {
    dispatch({ type: "SET_OCCUPENCY", payload: values });
  };

  //get values from scheduler
  const addToScheduler = (values) => {
    dispatch({ type: "ADD_TO_SCHEDULER", payload: values });
  };
  const setSchedulerData = (values) => {
    dispatch({ type: "GRID_DATA", payload: values });
  };
  const roomshiftingdatafunc = (values) => {
    dispatch({ type: "ROOM_SHIFT_DATA", payload: values });
  };

  //constant schedule data
  const setSchedulerData_original_func = (values) => {
    dispatch({ type: "SCHEDULE_DATA_CONST", payload: values });
  };
  //get guest house name
  const getGhName = (values) => {
    if (values?.some((item) => item !== undefined)) {
      const arrUniq = [
        ...new Map(values.map((v) => [JSON.stringify([v.Property, v.Id, v.CityName, v.StateName]), v])).values(),
      ];
      dispatch({ type: "GET_GH_NAME", payload: arrUniq });
    }
  };
  //show gh
  const setShowGhfunc = (values) => {
    dispatch({ type: "SHOW_GH", payload: values });
  };
  //show gh
  const setshowGhname = (values) => {
    dispatch({ type: "SET_SHOW_GH", payload: values });
  };
    //setinitialday
  const setinitialday = (values) => {
    dispatch({ type: "SET_INITIAL_DAY", payload: values });
  
  };
  //editdatafunc
  const getghroomnamefunc = (values) => {
    dispatch({ type: "GET_GH_ROOM_NAME", payload: values });
  };
  //editdatafunc
  const getghroomidfunc = (values) => {
    dispatch({ type: "GET_GH_ROOM_ID", payload: values });
  };
  //getroomidfunc
  const getroomidfunc = (values) => {
    dispatch({ type: "GET_ROOM_ID", payload: values });
  };
  //getgroupidfunc
  const getgroupidfunc = (values) => {
    dispatch({ type: "GET_GROUP_ID", payload: values });
  };
  const ghBookingData = (value) => {
    dispatch({ type: "GET_Booking_Data", payload: value });
  };
  // //get newBookingData
  // const newBookingDatafunc = (values) => {
  //   dispatch({ type: "NEW_BOOKING_DATA", payload: values });
  // };
  const newbookingpopupfunc = (values) => {
    dispatch({ type: "NEWBOOKINGDATA", payload: values });
  };
  //show new booking
  const showNewBookingfunc = (values) => {
    dispatch({ type: "SHOW_NEW_BOOKING", payload: values });
  };

  //TABLE_DATA ( no func)

  //get additionalfields
  const addfieldsData = (values) => {
    dispatch({ type: "ADDITIONAL_DATA", payload: values });
  };

  const show_error_templatefunc = (values) => {
    dispatch({ type: "SHOW_ERROR_TEMPLATE", payload: values });
  };
  const getErrorMessagefunc = (values) => {
    dispatch({ type: "GET_ERROR_MSG", payload: values });
  };
  const set_Error_Message_func = (values) => {
    dispatch({ type: "SET_ERROR_MSG_FUNC", payload: values });
  };
  const set_modify_Error_Message_func = (values) => {
    dispatch({ type: "SET_MODIFY_ERROR_MSG_FUNC", payload: values });
  };
  // error component accept button enable
  const errorAcceptButtonfunc = (values) => {
    dispatch({ type: "ERROR_ACCEPT_BUTTON", payload: values });
  };
  // error button validation msg
  const errorbuttonvalidationmsg = (values) => {
    dispatch({ type: "ERROR_BUTTON_VALIDATION", payload: values });
  };
  const errorClassNamefunc = (values) => {
    dispatch({ type: "GET_CLASS_NAME", payload: values });
  };
  //error message color
  const error_msg_color_func = (values) => {
    dispatch({ type: "ERROR_MSG_COLOR", payload: values });
  };
  const loadspinloaderfunc = (values) => {
    dispatch({ type: "LOAD_GRID_SPINNER", payload: values });
  };
  const isLoadingfunc = (values) => {
    dispatch({ type: "IS_LOADING", payload: values });
  };
  const setStartdate = (values) => {
    dispatch({ type: "SET_START_DATE", payload: values });
  };
  //show setEnddate
  const setEnddate = (values) => {
    dispatch({ type: "SET_END_DATE", payload: values });
  };

  //show setStartdate grid
  const setStarttime = (values) => {
    let sdate = values;
    let shours = ("0" + sdate.getHours()).slice(-2);
    let sminutes = ("0" + sdate.getMinutes()).slice(-2);
    let sseconds = ("0" + sdate.getSeconds()).slice(-2);
    let s_datetime = shours + "/" + sminutes + "/" + sseconds + " " + (shours > 12 ? "AM" : "PM");

    dispatch({ type: "SET_START_TIME", payload: s_datetime });
  };
  //show setEnddate grid
  const setEndtime = (values) => {
    let edate = values;
    let ehours = ("0" + edate.getHours()).slice(-2);
    let eminutes = ("0" + edate.getMinutes()).slice(-2);
    let eseconds = ("0" + edate.getSeconds()).slice(-2);
    let e_datetime = ehours + "/" + eminutes + "/" + eseconds + " " + (ehours > 12 ? "PM" : "AM");
    dispatch({ type: "SET_END_TIME", payload: e_datetime });
  };
  const gridstartenddate = (values) => {
    dispatch({ type: "GRID_START_END_DATE", payload: values });
  };
  const constgridenddate = (values) => {
    dispatch({ type: "CONST_END_DATE", payload: values });
  };
  const staydayscountfunc = (values) => {
    dispatch({ type: "STAY_DAYS_COUNT", payload: values });
  };
  const staydaystodisplayfunc = (values) => {
    dispatch({ type: "STAY_DAYS_COUNT_DISPLAY", payload: values });
  };
  const newdatefunc = (values) => {
    dispatch({ type: "NEWDATE", payload: values });
  };
  const editorcancelenddatefunc = (values) => {
    dispatch({ type: "EDITORENDDATEFUNC", payload: values });
  };
  const dayscountfunc = (values) => {
    dispatch({ type: "DAYS_COUNT", payload: values });
  };
  //show setEnddate
  const setCancelEvent = (values) => {
    dispatch({ type: "SET_CANCEL_EVENT", payload: values });
  };
  // const bookingcanceldatafunc = (values) => {
  //   dispatch({ type: "BOOKING_CANCEL_DETAIL", payload: values });
  // };
  //changeeventdatafunc
  const changeeventdatafunc = (values) => {
    dispatch({ type: "CHANGE_EVENT_DATA", payload: values });
  };
  const setDeleteDatafunc = (values) => {
    dispatch({ type: "DELETEDATA", payload: values });
  };
  //editmodificationfunc
  const editmodificationfunc = (values) => {
    dispatch({ type: "EDIT_MODIFICATION_DATA", payload: values });
  };
  //setErrorMsgClassfunc
  const setErrorMsgClassfunc = (values) => {
    dispatch({ type: "ERROR_MSG_CLASS", payload: values });
  };
  //stayChangedatefunc
  const stayChangedatefunc = (values) => {
    dispatch({ type: "SET_CHANGE_DATA", payload: values });
  };

  //seteventchangedatefunc
  const seteventchangedatefunc = (values) => {
    dispatch({ type: "SET_CHANGE_EVENT_DATA", payload: values });
  };

  //stayChangedatefunc
  const setEditordatafunc = (values) => {
    dispatch({ type: "SET_EDITOR_DATA", payload: values });
  };
  //bookingmodifycanceldatafunc
  const bookingmodifycanceldatafunc = (values) => {
    dispatch({ type: "SET_BOOKING_MODIFT_CANCEL_DATA", payload: values });
  };
  //editdatafunc
  const editdatafunc = (values) => {
    dispatch({ type: "EDIT_DATA", payload: values });
  };
  //newbookingpopupdatafunc
  const newbookingpopupdatafunc = (values) => {
    dispatch({ type: "NEW_BOOKING_POPUP_DATA", payload: values });
  };
  //settestdatefunc
  const settestdatefunc = (values) => {
    dispatch({ type: "SET_TEST_DATE", payload: values });
  };
  //blockdeletefunc
  const blockdeletefunc = (values) => {
    dispatch({ type: "BLOCK_DELETE_DATA", payload: values });
  };
  //showblockdeletefunc
  const showblockdeletefunc = (values) => {
    dispatch({ type: "SHOW_BLOCK_DELETE_DATA", payload: values });
  };

  //newDatabookingfunc
  const newdatabookingfunc = (values) => {
    dispatch({ type: "SET_NEW_BOOKING_DATA", payload: values });
  };

    //newDatabookingfunc
    const changeroomidfunc = (values) => {
      dispatch({ type: "CHANGE_ROOM_ID", payload: values });
    };

    //newDatabookingfunc
    const showintofunc = (values) => {
      dispatch({ type: "SHOW_INTRO", payload: values });
    };

  //ghaccessdata
  const propertyaccess = (values) => {
    dispatch({ type: "GH_ACCESS_DATA", payload: values });
  };
    //ghaccessdata
    const editmodicheckfunc = (values) => {
      dispatch({ type: "MODIFY_CHECK_DATA", payload: values });
    };
    //ghaccessdata
    const stayshiftfunc = (values) => {
      dispatch({ type: "STAY_SHIFT", payload: values });
    };

     //setOccupancycheckflag
  const setOccupancycheckflag = (values) => {
    dispatch({ type: "GH_OCCUPANCYFLAG_DATA", payload: values });
  };
  //booking modification or cancel data
  const bookingmodifycancelfunc = (values) => {
    isLoadingfunc(true);
    bookingmodifycanceldatafunc([]);

    axios
      .post(`${state?.proxy_Url}/GetBookingCancellationDetails`, values?.modifydata, { headers: headers })
      .then((res) => {
        let result = res.data?.BookingCancelType?.filter(item => item?.CancelType !== "Partial Cancellation" && item);
       let resultset = {...res.data,BookingCancelType:result};


        bookingmodifycanceldatafunc(resultset);
        // bookingcanceldatafunc(res?.data);
        // dispatch({ type: "BOOKING_MODIFY_CANCEL_DATA", payload: res.data });
        gridoccupencydatafunc(values?.gridData);
        isLoadingfunc(false);
      })
      .catch((error) => {
        isLoadingfunc(false);
      });
  };

  //Enter number only
  const textNum = (e) => {
    return e.target.value?.replace(/\D/g, "");
  };

  //new dates

  // //show ghcomponent
  // const setShowGh = (values) => {
  //   dispatch({ type: "SCHEDULE_DATA_CONST", payload: values });
  // };
  //show setStartdate

  //get grid & occupency data

  const gridoccupencydatafunc = useCallback((values) => {
    if (values?.PropertyId !== undefined) {
      if (values?.action === "delete") {
        isLoadingfunc(true);
      }
      // const headers = {
      //   "Content-Type": "application/json",
      //   "x-access-token": `${state.token}`,
      // };

      let bodyData = {
        PropertyId: `${values?.PropertyId}`,
        FromDte: values?.FromDte,
        ToDte: values?.ToDte,
      };

      axios
        .post(`${state.proxy_Url}/hotelsearch`, bodyData, { headers: headers })
        .then((data) => {
          let Rdata = data;
          Rdata.data.Result[0].map((item) => (item.Subject = item.GuestName));
          let shedule_data = Rdata?.data?.Result[0];

          // all blocked data moved to single column in grid fix
          //MANIPULATE DATE FOR BLOCK AND ADD BED COUNT
          let displaygriddata = shedule_data.map((item, i) =>
            item.Type === "Blocked"
              ? {
                  ...item,
                  RoomName: item.BlocktableId,
                  BedId: item.BlocktableId,
                  BookingCode: item.BlocktableId,
                  BookingId: item.BlocktableId,
                  Start_Time: "12:00 PM",
                  End_Time: "11:59 AM",
                  // Subject: .split(" ").slice(0,4).join(" "),
                }
              : item
          );

          const newData = displaygriddata.map((obj1) => {
            const matchedObj = Rdata?.data?.Result[2].find((obj2) => parseInt(obj1.RoomId) === parseInt(obj2.RoomId));
            if (matchedObj) {
              return { ...obj1, bedcount: parseInt(matchedObj.BedCount) < 3 ? parseInt(matchedObj.BedCount) : 2 };
            }
            return obj1;
          });
    
          setSchedulerData({
            scheduleData: newData || displaygriddata,
            groupData: Rdata?.data?.Result[1],
            roomData: Rdata?.data?.Result[2],
          });
          setSchedulerData_original_func({
            scheduleData: newData || displaygriddata,
            groupData: Rdata?.data?.Result[1],
            roomData: Rdata?.data?.Result[2],
          });

          isLoadingfunc(false);
        })
        .catch((error) => {
          isLoadingfunc(false);
          setSchedulerData({
            scheduleData: [],
            groupData: [],
            roomData: [],
          });
        });

      //////

      let occpencyData = {
        PropertyId: values?.PropertyId,
        FromDate: values?.FromDte,
        ToDate: values?.ToDte,
      };

      axios
        .post(`${state.proxy_Url}/ViewOccupanyDetail`, occpencyData, { headers: headers })
        .then(({ data }) => {
        let  ViewOccupanyDetaildata = data
          //isLoadingfunc(true);
          // let roomnamechange = data?.Result[1].map((item) =>
          //   item.text.includes("Room Occupancy")
          //     ? { ...item, text: item.text.replace("Room Occupancy", "Rooms Occupied") }
          //     : item.text.includes("Room Available")
          //     ? { ...item, text: item.text.replace("Room Available", "Rooms Available") }
          //     : item.text.includes("Room Per") ? { ...item, text: item.text.replace("Room Per", "Room Percentage") } :item
          // );
          // .includes("Room Occupancy") ? item.text.replace("Room Occupancy", "Room Occupied") : item.text}
        
          let occupancybodydata = {
            PropertyId: values?.PropertyId,
            FromDt: values?.FromDte,
            ToDt: values?.ToDte,
            UserId:ghuserId

          }
let occupancyarray;

          axios
        .post(`${state.proxy_Url}/propertyoccupencydatacheck
        `, occupancybodydata, { headers: headers })
        .then(({ data }) => {
        
          occupancyarray = data.Result;
        

        let monthdata = ViewOccupanyDetaildata?.Result[0].filter(item => item.GHRoomid === 1 );
      

/* booking occupancy data processing start */
if(occupancyarray[2]?.length > 0 && occupancyarray !== 'No records found'){

let datechangedata = occupancyarray[2]?.map(item => {
  
  let datesplit = item?.CheckInDt?.split(" ") // Split the date string

let datearray = datesplit[0]?.split("-")
return {...item,extracted_date:datearray[1]+'/'+datearray[2]+'/'+datearray[0] };
})

function isUnique(obj, index, self) {
  return (
    index ===
    self.findIndex(
      (item) =>
        item.RoomId === obj.RoomId && item.extracted_date === obj.extracted_date
       
    )
  );
}

// Use the custom function with filter to keep only unique objects
const uniqueArray = datechangedata /*.filter(isUnique);*/

// add booking count start
const counts = {};

// Iterate through the array to count occurrences of the key's value
uniqueArray.forEach(item => {
  const keyValue = item['extracted_date'];
  if (counts[keyValue]) {
    counts[keyValue]++;
  } else {
    counts[keyValue] = 1;
  }
});

// Iterate through the array again to update the count property

//CheckInDt == extracted_date
const updatedArray = uniqueArray.map(item => {
  const keyValue = item['extracted_date'];
  if (counts[keyValue] > 1) {
    return { ...item, count: counts[keyValue] };
  } else {
    return { ...item, count: 1 };
  }
});



// add booking count end








let result = [];
let uniquearraymap = monthdata.forEach(obj1 => {
  // Find the matching object in the second array based on the 'id' property
  const matchingObj2 = updatedArray.find(obj2 => obj2.extracted_date === obj1.StartTime );
 
  if (matchingObj2) {

   
    result.push({
      ...obj1,
      StartTime:matchingObj2.extracted_date,
      EndTime:matchingObj2.extracted_date,
      GHgroupid: 1,
    GHRoomid: 6,
    Subject:`${matchingObj2.count}`
    
  })}
    else {

      result.push({
        ...obj1,
              GHgroupid: 1,
              GHRoomid: 6,
              Subject:"0"
      })
  }






    
    

});





/* booking occupancy data processing start */


setTimeout(() => {
  let resultdate = ViewOccupanyDetaildata?.Result[1]?.map(item => ({...item,text:item?.text.replace('(',`<span class ='occupencytot'> = `).replace(')',"</span>")}));
  
          setOccupencyData({
            scheduleData: [...ViewOccupanyDetaildata?.Result[0],...result],

            groupData: [
              {
                text: "Occupency (Total)",
                id: 1,
                groupId: 1,
              },
            ],
            
            roomData: [...resultdate,{
          //  roomData: [...resultdate,{
             // "text": `Rooms Occupancy <span class ='occupencytot'> = ${uniqueArray?.length}</span>`,
              "text": `No of Guest <span class ='occupencytot'> = ${uniqueArray?.length}</span>`,
              "id": 6,
              "groupId": 1
          }],
          });
         
          isLoadingfunc(false);
          

        },500)
      }
      else {
        setTimeout(() => {
          let result = [];
          let uniquearraymap = monthdata.map(item => ({...item,Subject:"0",GHgroupid: 1,
          GHRoomid: 6}))
          
                  setOccupencyData({
                    scheduleData: [...ViewOccupanyDetaildata?.Result[0],...uniquearraymap],
        
                    groupData: [
                      {
                        text: "Occupency (Total)",
                        id: 1,
                        groupId: 1,
                      },
                    ],
                    roomData: [...ViewOccupanyDetaildata?.Result[1],{
                      "text": `No of Guest (0)`,
                      "id": 6,
                      "groupId": 1
                  }],
                  });
                  isLoadingfunc(false);
                },500)
      }
        })
      }).catch(error => {})
        .catch((error) => {
          isLoadingfunc(false);
        });
      //  dispatch({ type: "SCHEDULE_DATA_CONST", payload: values});
    }
  }, []);

  // get and set gh name
  const getandsetGHName = (val) => {
    setTimeout(() => {
      setshowGhname(val?.Property);
      //send selected property to get customer details
      let bodyData = {
        PropertyId: val?.Id, //propertuId from Property selection
        UserId: ghuserId,
      };
      getsetentityname(bodyData);
      // setIsLoading(!isLoading);
    });
  };
  //select entityname(client name) from property
  // let propid = state.propertyID[0];
  const getsetentityname = async (values) => {
    if (values?.PropertyId !== undefined) {
      // const headers = {
      //   "Content-Type": "application/json",
      //   "x-access-token": `${state.token}`,
      // };

      axios
        .post(`${state.proxy_Url}/ClientLoad`, values, { headers: headers })
        .then(({ data }) => {
          let Rdata = data?.Result[0];
          //get client full details
//console.log(data,"client data")
          ghClientDetails(data?.Result[1]);
          ghclientfulldetails(data?.Result)
          let GHResult = [];
          //  let GHId = "";
          Rdata.map((item) => (typeof item.ClientName === "string" ? GHResult.push(item?.ClientName) : null));
          //get property client details
          getGHClientName([...data?.Result]);

          //get client id if length is 1
          if (data?.Result[0].length === 1) {
            ghClientID(data?.Result[0][0]?.Id);

            clientcustomfields(data?.Result[0][0]?.Id);
            //get new booking data details

            getnewbookingdata(data?.Result[0][0]?.Id, values?.PropertyId);
          } else {
            ghClientID([]);
          }

          // ghClientID(Rdata[0]?.Id);
          getclientidfunc(Rdata[0]?.Id);

          getClientFlagDetailsfunc(data?.Result[1]);
          //  GHId = typeof Rdata[0]?.Id === "string" ? Rdata[0]?.Id : null;
        })
        .catch((error) => {
          //   getGHClientName([]);
          //   getGHClientName( [
          //   [
          //       {
          //         "Id": "0",
          //         "ClientName": "Select Entity Name"
          //       }
          //   ],
          // ])
        });
    }
  };

  const convertdatetommddyy = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      //  hours = ("0" + date.getHours()).slice(-2),
      //  minutes = ("0" + date.getMinutes()).slice(-2),
      //  seconds = ("0" + date.getSeconds()).slice(-2),
      //  ampm = hours > 12 ? "PM" : "AM",
      year = date.getFullYear();
    return `${mnth}/${day}/${year}`;
  };

  //format date
  const formatDateddmmyy = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };
  const clientcustomfields = (val) => {
    let bodyData = {
      ClientId: val,
      UserId: ghuserId,
    };

    if (val !== undefined) {
      axios
        .post(`${state.proxy_Url}/ClientCustomfield1`, bodyData, { headers: headers })
        .then(({ data }) => {
          dispatch({ type: "CLIENT_CUSTOM_FIELDS_COLLECTION", payload: data?.Result });
          
        })
        .catch((error) => {});
    }
  };

  // propertyload func call
  const propertyloadfunc = () => {
    let bodyData = {
      UserId: ghuserId,
    };

    axios
      .post(`${state.proxy_Url}/PropertyLoad`, bodyData, { headers: headers })
      .then(({ data }) => {
        let Rdata = data.Result[0];
        getGhName(Rdata);
        isLoadingfunc(false);
      })
      .catch((error) => {
        
      });
  };

  //get new booking data

  const getnewbookingdata = (value1, value2) => {
    let bodyData = {
      ClientId: value1 !== "" && value1,
      PropertyId: value2 !== "" && value2,
      UserId: ghuserId,
    };

    axios
      .post(`${state.proxy_Url}/NewBookingLoadDetail`, bodyData, { headers: headers })
      .then(({ data }) => {
        // newBookingDatafunc(data.Result);
        newdatabookingfunc(data.Result);
        if(document.querySelector('#ghproperty').value.toLowerCase().includes("icici"))
        {
          setTimeout(() => {
            isLoadingfunc(false);
          },1000)
        }
        else {
          isLoadingfunc(false);
      }
     
      })
      .catch((error) => {
        isLoadingfunc(false);
      });
  };

  const ghaccessdatafunc = () => {
    if (ghuserId) {
      let bodyData = {
        UserId: ghuserId,
      };

      axios
        .post(`${state.proxy_Url}/GHAccess`, bodyData, { headers: headers })
        .then(({ data }) => {
          propertyaccess(data.Result[0][0]);
        })
        .catch((error) => {
          isLoadingfunc(false);
        });
    }
  };

  // space trim
  const propInput = (e) => {
    if (/^\s/g.test(e.target.value)) {
      e.target.value = e.target.value?.replace(/^\s/g, "");
    } else {
      e.target.value = e.target.value?.replace(/^[ \t]+|[ \t]+$/gm, " ");
    }
  };

  return (
    <BookingContent.Provider
      value={{
        stayChangedatefunc,
        staychangedate: state.staychangedate,
        dashboard: state.dashboard,
        addToDashboard,
        scheduler: state.scheduler,
        addToScheduler,
        ghName: state.ghName,
        getGhName,
        occupencyChat: state.occupency,
        getGhOccupency,
        propertyID: state.propertyID,
        getPropertyId,
        userID: state.userid,
        User_Details: state.User_Details,
        clientID: state.clientID,
        ghClientID,
        clientDetails: state.clientDetails,
        ghClientDetails,
        ghclientfulldetails,
        clientfulldetails:state.clientfulldetails,
        getLoginAuth,
        propertyLoad: state.propertyLoad,
        addToProperty,
        getempdetfTR: state.getempdetfTR,
        empcodeload,
        proxyUrl: state.proxyUrl,
        ghBookingData,
        bookingdata: state.bookingdata,
        token: state.token,
        additionalfields: state.additionalfields,
        addfieldsData,
        // newDatabooking: state.newDatabooking,
        // newBookingDatafunc,
        proxy_Url: state.proxy_Url,
        tr_select_details: state.tr_select_details,
        getTrSelectDetails,
        showerror_template: state.showerror_template,
        err_msg: state.err_msg,
        error_class_name: state.error_class_name,
        show_error_templatefunc,
        getErrorMessagefunc,
        errorClassNamefunc,
        is_loading: state.is_loading,
        isLoadingfunc,
        property_details: state.property_details,
        getPropertyDetails,
        GH_property_ClientName: state.GH_property_ClientName,
        getGHClientName,
        scheduler_Data: state.scheduler_Data,
        setSchedulerData,
        textNum,
        error_msg_color: state.error_msg_color,
        error_msg_color_func,
        enablespinner: state.enablespinner,
        loadspinloaderfunc,
        // new details
        tr_details: state.tr_details,
        settrdetailsfunc,
        //constant schedule data
        setSchedulerData_original_func,
        scheduledataoriginal: state.scheduledataoriginal,
        setOccupencyData,
        occupency_Data: state.occupency_Data,
        // grid data
        gridoccupencydatafunc,
        start_date: state.start_date,
        setStartdate,
        end_date: state.end_date,
        setEnddate,
        getsetentityname,
        errorAcceptButtonfunc,
        errorAcceptButton: state.errorAcceptButton,
        errorvalidationButton: state.errorvalidationButton,
        errorbuttonvalidationmsg,
        start_time: state.start_time,
        end_time: state.end_time,
        setStarttime,
        setEndtime,
        setCancelEvent,
        cancel_event: state.cancel_event,
        convertdatetommddyy,
        formatDateddmmyy,
        setghOccupency,
        set_occupency: state.set_occupency,
        gridstartend_date: state.gridstartend_date,
        gridstartenddate,
        constenddate: state.constenddate,
        constgridenddate,
        getpropertynameandid,
        propnameid: state.propnameid,
        // bookingcanceldata: state.bookingcanceldata,
        // bookingcanceldatafunc,
        staydayscount: state.staydayscount,
        staydayscountfunc,
        staydaystodisplayfunc,
        staydaystodisplay: state.staydaystodisplay,

        newdate: state.newdate,
        newdatefunc,
        editorcancelenddate: state.editorcancelenddate,
        editorcancelenddatefunc,
        newbooking_data: state.newbooking_data,
        newbookingpopupfunc,
        setDeleteDatafunc,
        delete_data: state.delete_data,
        showGh: state.showGh,
        setShowGhfunc,
        getandsetGHName,
        showGhname: state.showGhname,
        setshowGhname,
        shownewbooking: state.shownewbooking,
        showNewBookingfunc,
        setShowTrfunc,
        showTr: state.showTr,
        changeeventdatafunc,
        change_event_data: state.change_event_data,
        editmodificationfunc,
        editmodificationdata: state.editmodificationdata,
        setEditordatafunc,
        seteditordata: state.seteditordata,
        booking_modify_cancel_data: state.booking_modify_cancel_data,
        bookingmodifycanceldatafunc,
        new_booking_popupdata: state.new_booking_popupdata,
        newbookingpopupdatafunc,
        new_booking_data: state.new_booking_data,
        newdatabookingfunc,
        edit_data: state.edit_data,
        editdatafunc,
        getghroomnamefunc,
        ghroom_name: state.ghroom_name,
        getghroomidfunc,
        ghroom_id: state.ghroom_id,
        getroomidfunc,
        room_id: state.room_id,
        getgroupidfunc,
        group_id: state.group_id,
        getclientidfunc,
        client_id: state.client_id,
        // ClientRequest:state.ClientRequest,
        // getClientRequestfunc,
        ClientFlagDetails: state.ClientFlagDetails,
        getClientFlagDetailsfunc,
        clientcustomfields,
        propertyloadfunc,
        getnewbookingdata,
        ghaccessdatafunc,
        ghaccessdata: state.ghaccessdata,
        //gh_accessdata: state.gh_accessdata,
        bookingmodificanceldata: state.bookingmodificanceldata,
        bookingmodifycancelfunc,
        clientcustomfieldscollection: state.clientcustomfieldscollection,
        set_Error_Message_func,
        set_err_msg: state.set_err_msg,
        set_modify_Error_Message_func,
        set_modify_err_msg: state.set_modify_err_msg,
        setErrorMsgClassfunc,
        error_msg_class: state.error_msg_class,
        seteventchangedatefunc,
        setstaychangedate: state.setstaychangedate,
        propInput,
        blockdeletefunc,
        block_delete_data: state.block_delete_data,
        showblockdelete: state.showblockdelete,
        showblockdeletefunc,
        dayscountfunc,
        dayscount: state.dayscount,
        stayshiftfunc,
        stayshift:state.stayshift,
        showintofunc,
        showinto:state.showinto,
        roomshiftdata:state.roomshiftdata,
        roomshiftingdatafunc,
        changeroomidfunc,
        changeroomid:state.changeroomid,
        getmodifyblockdata,
        blockmodify:state.blockmodify,
        getmodifyhistorydata,
        blockhistory:state.blockhistory,
        editmodicheckfunc,
        editmodicheck:state.editmodicheck,
        settestdatefunc,
        testdate:state.testdate,
        fromhomepagepropertydata:state.fromhomepagepropertydata,
        fromhomepagepropertyfunc,
        modifysuccessMessage:state.modifysuccessMessage,
        setmodifysuccessMessage,
        initial_day:state.initial_day,
        setinitialday,
        settime:state.settime,
        setOccupancycheckflag,
        Occupancycheckflag:state.Occupancycheckflag
        
      }}
    >
      {props.children}
    </BookingContent.Provider>
  );
};
