import React, { useState, useEffect, useRef, useContext } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

const CustomFieldsEdit = ({ e_customFields, setE_customFields }) => {
  const { editmodificationdata, editmodificationfunc, edit_data, clientcustomfieldscollection, propInput } =
    useContext(BookingContent);
  let field1 = useRef();
  let field2 = useRef();
  let field3 = useRef();
  let field4 = useRef();
  let field5 = useRef();
  let field6 = useRef();
  let field7 = useRef();
  let field8 = useRef();
  let field9 = useRef();
  let field10 = useRef();
  const [editFormData, setEditFormData] = useState({
    column1: "",
    column2: "",
    column3: "",
    column4: "",
    column5: "",
    column6: "",
    column7: "",
    column8: "",
    column9: "",
    column10: "",
  });
  let edit_cus_data = edit_data;
  //JSON.parse(localStorage.getItem("editData"));
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);

  useEffect(() => {
    if (edit_cus_data) {
      setEditFormData((prev) => ({
        ...prev,
        column1: editmodificationdata.CustomFieldDetails[0][0]?.Column1,
        column2: editmodificationdata.CustomFieldDetails[0][0]?.Column2,
        column3: editmodificationdata.CustomFieldDetails[0][0]?.Column3,
        column4: editmodificationdata.CustomFieldDetails[0][0]?.Column4,
        column5: editmodificationdata.CustomFieldDetails[0][0]?.Column5,
        column6: editmodificationdata.CustomFieldDetails[0][0]?.Column6,
        column7: editmodificationdata.CustomFieldDetails[0][0]?.Column7,
        column8: editmodificationdata.CustomFieldDetails[0][0]?.Column8,
        column9: editmodificationdata.CustomFieldDetails[0][0]?.Column9,
        column10: editmodificationdata.CustomFieldDetails[0][0]?.Column10,
      }));
    }

    return () => {
      //setAddFormData([]);
      //addfieldsData([]);
    };
  }, []);
  //onChange event
  const handleChange = (event) => {
    const value = event.target.value;
    setEditFormData({ ...editFormData, [event.target.name]: value });
  };
  //close popup page
  const closeFields = () => {
    setE_customFields(!e_customFields);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    //     addfieldsData(addFormData)
    //     setAddifields(!addifields)

    // let guestdata = JSON.parse(localStorage.getItem("guestDetails"));
    // let merge_Guest_Date = Object.assign({},guestdata,addFormData)
    // let get_employee = JSON.parse(localStorage.getItem("trInputstates"))
    // merge_Guest_Date.EmployeeId = get_employee.EmployeeId;
    // merge_Guest_Date.GradeId = get_employee.GradeId;

    // localStorage.setItem("guestDetails",JSON.stringify(merge_Guest_Date));
  };

  const EditcustomFields = () => {
    let col1 = document.querySelector("#c_fields_1")?.value;
    let col2 = document.querySelector("#c_fields_2")?.value;
    let col3 = document.querySelector("#c_fields_3")?.value;
    let col4 = document.querySelector("#c_fields_4")?.value;
    let col5 = document.querySelector("#c_fields_5")?.value;
    let col6 = document.querySelector("#c_fields_6")?.value;
    let col7 = document.querySelector("#c_fields_7")?.value;
    let col8 = document.querySelector("#c_fields_8")?.value;
    let col9 = document.querySelector("#c_fields_9")?.value;
    let col10 = document.querySelector("#c_fields_10")?.value;

    if (
      (col1 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column1Mandatory === true) ||
      (col2 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column2Mandatory === true) ||
      (col3 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column3Mandatory === true) ||
      (col4 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column4Mandatory === true) ||
      (col5 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column5Mandatory === true) ||
      (col6 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column6Mandatory === true) ||
      (col7 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column7Mandatory === true) ||
      (col8 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column8Mandatory === true) ||
      (col9 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column9Mandatory === true) ||
      (col10 === "" && editmodificationdata?.CustomFieldDetails[1][0]?.Column10Mandatory === true)
    ) {
      setgetErrorMessagefunc([
        col1 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column1Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column1} is required`,
        col2 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column2Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column2} is required`,
        col3 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column3Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column3} is required`,
        col4 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column4Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column4} is required`,
        col5 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column5Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column5} is required`,
        col6 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column6Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column6} is required`,
        col7 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column7Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column7} is required`,
        col8 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column8Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column8} is required`,
        col9 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column9Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column9} is required`,
        col10 === "" &&
          editmodificationdata?.CustomFieldDetails[1][0]?.Column10Mandatory === true &&
          `${editmodificationdata?.CustomFieldDetails[1][0]?.Column10}  is required`,
      ]);
    } else {
      setE_customFields(!e_customFields);

      //  let ss = {...editData.CustomFieldDetails[0][0],Column1 : editFormData.column1,
      let ss = [
        {
          ...editmodificationdata.CustomFieldDetails[0][0],
          Column1:
            document.querySelector("#c_fields_1")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column1 ||
            "",
          Column2:
            document.querySelector("#c_fields_2")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column2 ||
            "",
          Column3:
            document.querySelector("#c_fields_3")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column3 ||
            "",
          Column4:
            document.querySelector("#c_fields_4")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column4 ||
            "",
          Column5:
            document.querySelector("#c_fields_5")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column5 ||
            "",
          Column6:
            document.querySelector("#c_fields_6")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column6 ||
            "",
          Column7:
            document.querySelector("#c_fields_7")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column7 ||
            "",
          Column8:
            document.querySelector("#c_fields_8")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column8 ||
            "",
          Column9:
            document.querySelector("#c_fields_9")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column9 ||
            "",
          Column10:
            document.querySelector("#c_fields_10")?.value ||
            editmodificationdata?.CustomFieldDetails[0][0].column10 ||
            "",
        },
      ];

      let get_custoized_data = { ...editmodificationdata.CustomFieldDetails, [0]: ss };

      editmodificationfunc({ ...editmodificationdata, CustomFieldDetails: get_custoized_data });
    }
  };
  const error_close = () => {
    setgetErrorMessagefunc([]);
  };

  // custom fields array
  let column_data;
  if (clientcustomfieldscollection !== "No records found") {
     column_data = clientcustomfieldscollection?.map((item) => item?.map((listitem) => listitem?.label));
  }
// console.log(clientcustomfieldscollection)
  return (
    <>
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Message</span>{" "}
              <div className="close_but bg-light" onClick={error_close}>
                X
              </div>
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="addFieldsbg" /*style={{display: addifields ? 'flex': 'none'}}*/>
        <div className="AddFieldsBlock">
          <div className="AddFields">
            <div className="AddFieldsheader d-flex">
              <span>Custom fields</span>{" "}
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
                X
              </span>{" "}
            </div>

            <div className="AddFieldsbody customentry">
              <form onSubmit={handlesubmit}>
                <table className="table" style={{ marginBottom: "0px !important" }}>
                  <tbody>
                    <>
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column1 ? (
                        <tr>
                          <td style={{ borderTop: "0px" }}>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column1}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column1Mandatory && " *"}
                            </span>
                          </td>
                          <td style={{ borderTop: "0px" }}>
                            <AutoCompleteComponent
                              id="c_fields_1"
                              name="Column1"
                              value={editFormData.column1}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column1Mandatory === true ? field1 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[0] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column2 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column2}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column2Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_2"
                              name="Column2"
                              value={editFormData.column2}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column2Mandatory === true ? field2 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[1] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column3 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column3}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column3Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_3"
                              name="Column3"
                              value={editFormData.column3}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column3Mandatory === true ? field3 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[2] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column4 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column4}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column4Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_4"
                              name="Column4"
                              value={editFormData.column4}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column4Mandatory === true ? field4 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[3] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column5 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column5}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column5Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_5"
                              name="Column5"
                              value={editFormData.column5}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column5Mandatory === true ? field5 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[4] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column6 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column6}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column6Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_6"
                              name="Column6"
                              value={editFormData.column6}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column6Mandatory === true ? field6 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[5] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column7 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column7}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column7Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_7"
                              name="Column7"
                              value={editFormData.column7}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column7Mandatory === true ? field7 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[6] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column8 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column8}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column8Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_8"
                              name="Column8"
                              value={editFormData.column8}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column8Mandatory === true ? field8 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[7] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column9 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column9}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column9Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_9"
                              name="Column9"
                              value={editFormData.column9}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column9Mandatory === true ? field9 : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[8] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}
                      {editmodificationdata.CustomFieldDetails[1][0]?.Column10 ? (
                        <tr>
                          <td>
                            {editmodificationdata.CustomFieldDetails[1][0]?.Column10}
                            <span className="text-danger">
                              {editmodificationdata.CustomFieldDetails[1][0]?.Column10Mandatory && " *"}
                            </span>
                          </td>
                          <td>
                            <AutoCompleteComponent
                              id="c_fields_10"
                              name="Column10"
                              value={editFormData.column10}
                              placeholder="Enter value"
                              className="form-control"
                              onChange={handleChange}
                              ref={
                                editmodificationdata.CustomFieldDetails[1][0]?.Column10Mandatory === true
                                  ? field10
                                  : null
                              }
                              dataSource={clientcustomfieldscollection !== "No records found" ? column_data[9] : []}
                              onInput={propInput}
                            />
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td colSpan="2">
                          <div className="AddFieldsfooter">
                            <button className="btn btn-primary btn-sm" onClick={EditcustomFields}>
                              Add
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CustomFieldsEdit);
