import React, { useContext, useCallback, useState, useRef, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { BookingContent } from "../bookingContext/BookingContext";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { Checkbox, CheckboxGroup } from "rsuite";
import BlockModifyData from "../eventChange/BlockModifyData";
import BlockHistory from "../eventChange/BlockHistory";
import SpinnerTemplate from "../error_component/Spinner";

const RoomBlock = () => {
  const {
    ghName,
    proxy_Url,
    getGhName,
    token,
    show_error_templatefunc,
    showerror_template,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    is_loading,
    propertyloadfunc,
    propInput,
    set_Error_Message_func,
    setErrorMsgClassfunc,
    getmodifyblockdata,
    blockmodify,
    blockhistory,
    getmodifyhistorydata,
    GH_property_ClientName,
    modifysuccessMessage,
  } = useContext(BookingContent);
  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  const [formValues, setFormValues] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [time_change, settime_changefunc] = useState("");
  const [timediff, settimediff] = useState(5);
  const [guesterrormsg, setguesterrormsg] = useState([]);
  const [getErrorMessage, setgetErrorMessage] = useState([]);
  const [bookinggetErrorMessage, setbookinggetErrorMessage] = useState([]);
  const [bookinggetsuccessErrorMessage, setbookinggetsuccessErrorMessage] = useState([]);
  const [set_modify, setModify] = useState([]);
  const [set_history, setHistory] = useState([]);
  const [errormsg, seterrormsg] = useState([]);
  let [selected, setSelected] = React.useState([]);
  let [toblockdata, settoblockdata] = React.useState([]);
  let [selectedall, selectedallfunc] = React.useState([]);
  let [intime_change, setintime_change] = React.useState("");
  let [outtime_change, setouttime_change] = React.useState("11:59 AM");
  let [mindate, setmindate] = React.useState(null);
  let [maxdate, setmaxdate] = React.useState(null);
  let [groupdata, setGroupdata] = React.useState([]);
  let [blockedRooms, setblockedRooms] = React.useState([]);
  let [roomdataDetails, setRoomdataDetails] = React.useState([]);
  let [RoomType, setRoomType] = React.useState([]);
  let [Propertyflag, setPropertyflag] = React.useState([]);
  const [ghpreopretySearch, setGhpreopretySearch] = useState("");
  const [mindate24, setmindate24] = useState("");
  const [block_htry, setblock_htry] = useState(false);
  const [property_room_filter, setproperty_room_filter] = useState([]);
  const [roomnotavailable, setroomnotavailable] = useState("Room not Available");
  let [selectedunblock, setSelectedunblock] = React.useState([]);
  let [selectedall_bulk, selectedallfunc_bulk] = React.useState([]);
  let [unblockallconfirm, setunblockallconfirm] = React.useState([]);
  let trselectref = useRef();

  let startObj = useRef();
  let endObj = useRef();
  let propertyloadref = useRef();
  let bookingStatusref = useRef();
  let roomnoref = useRef();
  let roomtyperef = useRef();
  let intimeobj = useRef();
  let outtime = useRef();

  useEffect(() => {
    propertyloadfunc();
    let todate = new Date(
      new Date(formValues.blockfromDate || new Date()).setDate(
        new Date(formValues.blockfromDate || new Date()).getDate() + 1
      )
    );
    setFormValues((pre) => ({ ...pre, blocktoDate: todate }));
    // selectedallfunc([]);

    setmindate(new Date(new Date().setDate(new Date().getDate() + 1)));
  }, []);

  useEffect(() => {
    const newData = roomdataDetails.map((obj1) => {
      const matchedObj = selected.find((obj2) => obj1.RoomId === obj2);
      if (matchedObj) {
        return obj1;
      }
      return matchedObj;
    });

    let filteredroom = newData.filter((item) => item !== undefined);
    setGroupdata(filteredroom);

    if (roomdataDetails?.length > 0 && roomdataDetails?.length === filteredroom?.length) {
      selectedallfunc(["Select All"]);
    } else {
      selectedallfunc([]);
    }
  }, [selected]);

  useEffect(() => {
    // const newData = roomdataDetails.map((obj1) => {
    //   const matchedObj = selected.find((obj2) => obj1.RoomId === obj2);
    //   if (matchedObj) {
    //     return obj1;
    //   }
    //   return matchedObj;
    // });

    // let filteredroom = newData.filter((item) => item !== undefined);
    // setGroupdata(filteredroom);
    //console.log(selectedunblock.length)
    //console.log(property_room_filter?.length > 0 && property_room_filter?.length === selectedunblock?.length)

    if (property_room_filter?.length > 0 && property_room_filter?.length === selectedunblock?.length) {
      selectedallfunc_bulk(["Unblock All"]);
    } else {
      selectedallfunc_bulk([]);
    }
  }, [selectedunblock]);

  const onSubmit1 = (e) => {
    setblock_htry(false);
    e.preventDefault();
  };

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setblock_htry(false);
    seterrormsg([]);

    let guesthousename = document.querySelector("#booking_ghname")?.value;

    let comments = document.querySelector("#blockroom")?.value;
    let getroomno;
    let roomcount = selected?.length;
    let property_id = ghName[0]?.filter((item) => item.Property?.trim() === guesthousename?.trim());

    if (property_id?.length > 0 && comments !== "" && selected?.length > 0 && timediff >= 4) {
      isLoadingfunc(true);
      getroomno = roomdataDetails?.map((item) => item?.RoomNoId);

      //let propertcheckindetails = blockedRooms?.length > 0 && blockedRooms?.filter((item) => (item.PropertyId == property_id[0]?.Id ? item : null));

      // block check before insert start
      let frmdate = document.querySelector("#blockfromDate")?.value.split("/");
      let todate = document.querySelector("#blocktoDate")?.value.split("/");
      let frmtime = document.querySelector("#blockintime")?.value;
      let totime = document.querySelector("#blockouttime")?.value;

      let blockcheckData = {
        PAction: "BlockblukCheck",
        // RoomId: '',
        FromDt: `${frmdate[2] + "-" + frmdate[1] + "-" + frmdate[0]}`,
        ToDt: `${todate[2] + "-" + todate[1] + "-" + todate[0]}`,
        FromTime: Propertyflag?.CheckIn_24HrsFlg === true ? frmtime?.replace(":00", ":00") : "12:00:00 PM",
        ToTime:
          Propertyflag?.CheckIn_24HrsFlg === true
            ? totime?.includes(":59")
              ? totime?.replace(":59", ":59")
              : totime?.replace(":00", ":00:00")
            : "11:59:00 AM",
        UserId: ghuserId,
        PropertyId: property_id[0]?.Id,
      };

      axios

        .post(`${proxy_Url}/BlockblukCheckDetail`, blockcheckData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.filter((item, i) => {
            selected.find((element) => element[i] == item.RoomId && item.RoomId);
          });

          let res = [];
          res = data?.Result[0]?.filter((el) => {
            return selected.find((element) => {
              return element === el.RoomId;
            });
          });
          let roomfiltered_id = res?.map((item) => item?.RoomId);

          // block check before insert end

          // block insert data
          setTimeout(() => {
            let s_dates = document.querySelector("#blockfromDate")?.value.split("/");
            let e_dates = document.querySelector("#blocktoDate")?.value.split("/");
            let bodyDatainsert = {
              RoomId: roomfiltered_id,
              FromDt: s_dates[2] + "-" + s_dates[1] + "-" + s_dates[0],
              ToDt: e_dates[2] + "-" + e_dates[1] + "-" + e_dates[0],
              FromTime: document.querySelector("#blockintime")?.value,
              ToTime: document.querySelector("#blockouttime")?.value,
              CheckIn24Hrs:
                Propertyflag?.CheckIn_24HrsFlg === null ? 0 : Propertyflag?.CheckIn_24HrsFlg === false ? 0 : 1,
              UserId: ghuserId,
              PropertyId: property_id[0]?.Id,
              Comments: document.querySelector("#blockroom")?.value,
            };

            axios
              .post(`${proxy_Url}/BlockblukInsertDetail`, bodyDatainsert, { headers: headers })
              .then(({ data }) => {
                isLoadingfunc(false);

                // error message

                setbookinggetsuccessErrorMessage(["Room Blocked Successfully"]);
                setblockedRooms([]);
                setSelected([]);
                setRoomdataDetails([]);
                document.querySelector("#blockroom").value = "";
                document.querySelector("#booking_ghname").value = "";
                setFormValues((pre) => [{ ...pre, GHName: "" }]);
                setTimeout(() => {
                  setbookinggetsuccessErrorMessage([]);
                }, 2000);
                // error message
              })
              .catch((error) => {
                //console.log(error.message);
              });
          }, 1000);
        })
        .catch((error) => {});

      setTimeout(() => {
        setgetErrorMessage([]);
      }, 2000);
      //selectedallfunc([]);
      //setSelected([]);
      //ghnamechange({value:document.querySelector("#booking_ghname")?.value})
      // clearData();
    } else {
      setgetErrorMessage([]);
      if (property_id?.length === 0) {
        setgetErrorMessage(["Please select guest house name from the list"]);
      } else {
        if (comments === "") {
          setgetErrorMessage((pre) => [...pre, "Please enter comments"]);
        }
        if (selected?.length === 0) {
          setgetErrorMessage((pre) => [...pre, "Please select minimum 1 room for block"]);
        }
      }
      if (timediff < 4) {
        setgetErrorMessage((pre) => [...pre, "Minimum 4 hours required for blocking"]);
      }

      setTimeout(() => {
        setbookinggetErrorMessage([]);
        setgetErrorMessage([]);
        isLoadingfunc(false);
      }, 2000);
    }
  });
  const ghnameBlur = (e) => {};

  const convertampm = (value) => {
    //blockcheckData
    let timedisplay = value.split(" ");

    return timedisplay[0] + ":00";
  };
  const ghchange = () => {
    let todate = new Date(new Date().setDate(new Date().getDate() + 1));

    setFormValues((pre) => ({ ...pre, blockfromDate: new Date(), blocktoDate: todate, GHName: "", blockroom: "" }));
    // selectedallfunc([]);

    setmindate(new Date(new Date().setDate(new Date().getDate() + 1)));
  };
  const handleSelect = (selectedValue) => {
    setintime_change(new Date(new Date().setHours(12, 0)));
    setouttime_change(new Date(new Date().setHours(11, 59)));

    setSelectedunblock([]);
  };

  const ghnamechange = (val) => {
    if (val !== "nodate") {
      handleSelect();
      ghchange();
    }
    setroomnotavailable("");
    let property_id_find = [];
    let bodyData = {
      UserId: JSON.parse(localStorage.getItem("accessToken"))?.Id,
    };

    axios
      .post(`${proxy_Url}/PropertyLoad`, bodyData, { headers: headers })
      .then(({ data }) => {
        property_id_find = data?.Result[0];

        let property_id = property_id_find?.filter(
          (item) =>
            item.Property?.toUpperCase().trim() ===
            document.querySelector("#booking_ghname")?.value.toUpperCase().trim()
        );

        setRoomdataDetails([]);
        setblockedRooms([]);
        setSelected([]);

        let samadayflagcheck;

        let blockedpropertyflagcheck = {
          PropertyId: property_id[0]?.Id,
          UserId: ghuserId,
        };

        axios
          .post(`${proxy_Url}/propertyflagcheck`, blockedpropertyflagcheck, { headers: headers })
          .then(({ data }) => {
            setPropertyflag(data?.Result[0][0]);
            samadayflagcheck = data?.Result[0][0];

            let frdate = document.querySelector("#blockfromDate").value.split("/");
            let fr_date = new Date(`${frdate[1]},${frdate[0]},${frdate[2]},`);
            if (data?.Result[0][0]?.CheckIn_24HrsFlg === true) {
              setmindate24(new Date(fr_date));
            } else {
              setmindate24(new Date(new Date(fr_date).getDate() + 1));
            }
          })
          .catch((error) => {});

        if (property_id?.length > 0) {
          // blocked data details
          let blockedbodyData = {
            PropertyId: property_id[0]?.Id,
            UserId: ghuserId,
            CheckIn24Hrs: Propertyflag?.CheckIn_24HrsFlg === null ? 0 : Propertyflag?.CheckIn_24HrsFlg,
          };

          axios
            .post(`${proxy_Url}/BlockPropertyDataDetails`, blockedbodyData, { headers: headers })
            .then(({ data }) => {
              setblockedRooms(data?.Result[0]);
              setproperty_room_filter(data?.Result[0]);
              isLoadingfunc(false);
            })
            .catch((error) => {});
          let frmdate = document.querySelector("#blockfromDate")?.value.split("/");
          let todate = document.querySelector("#blocktoDate")?.value.split("/");
          let frmtime = document.querySelector("#blockintime")?.value;
          let totime = document.querySelector("#blockouttime")?.value;

       setTimeout(() => {
            let blockcheckData = {
            PAction: "BlockblukCheck",
            // RoomId: '',
            FromDt: `${frmdate[2] + "-" + frmdate[1] + "-" + frmdate[0]}`,
            ToDt: `${todate[2] + "-" + todate[1] + "-" + todate[0]}`,
            FromTime: samadayflagcheck?.CheckIn_24HrsFlg === true ? frmtime?.replace(":00", ":00") : "12:00:00 PM",
            ToTime:
            samadayflagcheck?.CheckIn_24HrsFlg === true
                ? totime?.includes(":59")
                  ? totime?.replace(":59", ":59")
                  : totime?.replace(":00", ":00:00")
                : "11:59:00 AM",
            UserId: ghuserId,
            PropertyId: property_id[0]?.Id,
          };

          setbookinggetErrorMessage([]);
          isLoadingfunc(true);
         
          axios
            // .post(`${proxy_Url}/BlockCheckDetails`, blockcheckData, { headers: headers })
            .post(`${proxy_Url}/BlockblukCheckDetail`, blockcheckData, { headers: headers })
            .then(({ data }) => {
              isLoadingfunc(false);

              let room_type = data?.Result[0]?.map((item) => item.RoomType);
              setRoomType(data?.Result[0]);
              setRoomdataDetails(data?.Result[0]);
              if (data?.Result[0]?.length === 0) {
                setroomnotavailable("Room not Available");
              }
            })

            .catch((error) => {});
          },1000)
        }
      })
      .catch((error) => {});
  };

  const modify = (val) => {
    getmodifyblockdata([val]);
    setModify([val]);
  };
  const history = () => {
    setblock_htry(true);
    setgetErrorMessage([]);
    // getmodifyhistorydata([val]);
    // setHistory([val]);
    let property_id = ghName[0]?.filter(
      (item) =>
        item.Property?.toUpperCase().trim() === document.querySelector("#booking_ghname")?.value.toUpperCase().trim()
    );
    if (property_id.length > 0) {
      let frmdate = document.querySelector("#blockfromDate")?.value.split("/");
      let todate = document.querySelector("#blocktoDate")?.value.split("/");

      let blockcheckData = {
        PAction: "BlockHistorynew",
        // RoomId: '',
        FromDt: `${frmdate[2] + "-" + frmdate[1] + "-" + frmdate[0]}`,
        ToDt: `${todate[2] + "-" + todate[1] + "-" + todate[0]}`,
        FromTime: "12:00:00",
        ToTime: "11:59:00",
        UserId: ghuserId,
        PropertyId: property_id[0]?.Id,
      };

      getmodifyhistorydata([blockcheckData]);
    } else {
      setgetErrorMessage(["Please select property name from the list"]);
      setTimeout(() => {
        setgetErrorMessage([]);
      }, 2000);
    }
  };

  const unblock = (val) => {
    isLoadingfunc(true);

    let property_id = ghName[0]?.filter(
      (item) => item.Property?.trim() === document.querySelector("#booking_ghname")?.value?.trim()
    );
    let deleteData = {
      PropertyId: `${val?.Id}`,
      RoomId: `${val?.RoomId}`,
      CheckIn24Hrs: val?.CheckIn_24HrsFlg === true ? 1 : 0,
      UserId: ghuserId,
    };

    // delete data
    //console.log(val)

    axios
      .post(`${proxy_Url}/BlockDeleteroomsDataDetails`, deleteData, { headers: headers })
      .then((res) => {
        setbookinggetsuccessErrorMessage(["Room unblocked successfully"]);
        setTimeout(() => {
          setbookinggetsuccessErrorMessage([]);
        }, 1000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });

        isLoadingfunc(false);

        ghnamechange();
      })
      .catch((error) => {
        isLoadingfunc(false);
      });
  };
  const HandleoutChange = (event) => {
    // data cleared
    setRoomdataDetails([]);
    setblockedRooms([]);
    setSelected([]);
    setroomnotavailable("");
    setFormValues((pre) => ({ ...pre, blocktoDate: event.value }));
    //ghnamechange();
    timecheck({
      intime: timeformatcheck(document.querySelector("#blockintime")?.value),
      outtime: timeformatcheck(document.querySelector("#blockouttime")?.value),
    });
    // let chekindate = document.querySelector("#blockfromDate")?.value;
    //    let chekoutdate = document.querySelector("#blocktoDate")?.value;
    // console.log(chekindate,chekoutdate)
    // if (chekindate === chekoutdate )  {
    //   setintime_change(intime_change);
    //   console.log(new Date(intime_change));
    //   console.log("first")

    // } else {
    //   setintime_change("12:00 PM");
    //   console.log("first1")
 
    //  // settime_changeoutfunc(new Date(time_change.setHours(time_change.getHours() + 4)));
    // }

  };
  const HandleChange = (event) => {
    // data cleared
    setRoomdataDetails([]);
    setblockedRooms([]);
    setSelected([]);
    setroomnotavailable("");
    let todate = new Date(new Date(event.value).setDate(new Date(event.value).getDate() + 1));
    setFormValues((pre) => ({ ...pre, blockfromDate: event.value, blocktoDate: todate }));
    // ghnamechange();
    setTimeout(() => {
      setmindate(new Date(new Date(event.value).setDate(new Date(event.value).getDate() + 1)));
    }, 1000);
    timecheck({
      intime: timeformatcheck(document.querySelector("#blockintime")?.value),
      outtime: timeformatcheck(document.querySelector("#blockouttime")?.value),
    });
  };
  const onFocussdate = () => {
    startObj.show();
    document.querySelector("#blockfromDate").blur();
  };
  const onFocusstodate = () => {
    endObj.show();
    document.querySelector("#blocktoDate").blur();
  };
  const onFocussfromtime = () => {
    intimeobj?.show();
    // document.querySelector("#blockintime").blur();
  };
  const onFocusstotime = () => {
    outtime?.show();
    //document.querySelector("#blockouttime").blur();
  };
  const clearData = (event) => {
    let todate = new Date(new Date().setDate(new Date().getDate() + 1));
    setFormValues((pre) => ({ ...pre, blockfromDate: new Date(), blocktoDate: todate, GHName: "", blockroom: "" }));
    setblockedRooms([]);
    setRoomdataDetails([]);
    setintime_change("12:00 PM");
    setouttime_change("11:59 AM");
    document.querySelector("#blockroom").value = "";
    //setSelected([]);
    setroomnotavailable();
    window.location.reload();
  };
  const onoutTimeChange = (args) => {
    // data cleared
    setRoomdataDetails([]);
    setblockedRooms([]);
    setSelected([]);

    timecheck({
      intime: timeformatcheck(document.querySelector("#blockintime")?.value),
      outtime: timeformatcheck(document.querySelector("#blockouttime")?.value),
    });
  };

  const timecheck = (args) => {
    let stimechange = document.querySelector("#blockfromDate")?.value;
    let etimechange = document.querySelector("#blocktoDate")?.value;
    let chntime = document.querySelector("#blockintime")?.value;
    let ottime = document.querySelector("#blockouttime")?.value;
    settimediff(5);

    if (stimechange === etimechange) {
      let diff = args?.outtime - args?.intime;
      if (diff < 4) {
        setgetErrorMessage(["Minimum 4 hours required for blocking"]);
        setTimeout(() => {
          setgetErrorMessage([]);
        }, 2000);
      }
      settimediff(diff);
    }
  };
  const timeformatcheck = (value) => {
    const [time, modifier] = value.split(" ");

    let [hours, minutes] = time.split(":");

    if (parseInt(hours) === 12 && modifier === "AM") {
      hours = 0;
    } else if (parseInt(hours) < 12 && modifier === "AM") {
      hours = parseInt(hours);
    } else if (parseInt(hours) === 12 && modifier === "PM") {
      hours = parseInt(hours);
    } else {
      hours = parseInt(hours) + 12;
    }

    return hours;
    //return `${hours}:${minutes}`;
  };
  const selectoptionprop = useCallback((val) => {
    setSelectedunblock([]);

    if (document.querySelector("#booking_ghname").value === "") {
      setgetErrorMessage(["Please select property name from the list"]);
      setTimeout(() => {
        setgetErrorMessage([]);
      }, 2000);
    } else {
      ghnamechange("nodate");
    }
  }, []);
  const onTimeChange = (args) => {
    // data cleared
    setRoomdataDetails([]);
    setblockedRooms([]);
    setSelected([]);

    timecheck({
      intime: timeformatcheck(document.querySelector("#blockintime")?.value),
      outtime: timeformatcheck(document.querySelector("#blockouttime")?.value),
    });

    setintime_change(args?.value);
    
    //setouttime_change(new Date(args?.value.setHours(args?.value.getHours() + 4)));
  };

  const BlockCheckDetailsfunc = (value) => {
    isLoadingfunc(true);

    // for (let k = 0; k < value?.length; k++) {
    let blockcheckData = {
      RoomId: value.toString(),
      FromDt: document.querySelector("#blockfromDate")?.value,
      ToDt: document.querySelector("#blocktoDate")?.value,
      FromTime: "12:00:00 PM" || document.querySelector("#blockintime")?.value,
      ToTime: "11:59:00 AM" || document.querySelector("#blockouttime")?.value,
      CheckIn24Hrs: 0,
      UserId: ghuserId,
    };
  
    axios
      .post(`${proxy_Url}/BlockblukCheckDetail`, blockcheckData, { headers: headers })
      .then(({ data }) => {
        isLoadingfunc(false);
        if (data?.Result === "No records found") {
          //  settoblockdata(pre => [...pre,value]);
          isLoadingfunc(false);
        }
      })
      .catch((error) => {
        // console.log(error.message);
        isLoadingfunc(false);
      });
    // }
  };

  const selectallfunc = (args) => {
    let filterrooms = roomdataDetails.map((item) => item?.RoomId);

    if (selectedall.length > 0) {
      setSelected([]);
    } else {
      setSelected(filterrooms);
    }
  };
  const selectallfunc_bulk = (args) => {
    let filterrooms = property_room_filter.map((val) => val?.RoomId + " " + val.FromDt + " " + val.ToDt);

    if (selectedall_bulk.length > 0) {
      setSelectedunblock([]);
    } else {
      setSelectedunblock(filterrooms);
    }
  };
  const handleOnChange = (args) => {};
  const setblockhtry = (val) => {
    setblock_htry(val);
  };

  //filter the blocked toom list

  const RoomBlock = (val) => {
    setproperty_room_filter(
      blockedRooms &&
        blockedRooms !== "N" &&
        blockedRooms?.filter((item) => {
          if (val === "") {
            return item;
          } else if (
            item.FromDt.toLowerCase().includes(val.toLowerCase()) ||
            item.ToDt.toLowerCase().includes(val.toLowerCase()) ||
            item.RoomNo.toLowerCase().includes(val.toLowerCase()) ||
            item.Comments.toLowerCase().includes(val.toLowerCase())
          ) {
            return item;
          }
        })
    );
    setGhpreopretySearch();
  };

  const unblockdatafunc = () => {
    setunblockallconfirm([]);
    let guesthousename = document.querySelector("#booking_ghname")?.value;
    let property_id = ghName[0]?.filter((item) => item.Property?.trim() === guesthousename?.trim());
    let bodyunblockdata = {
      roomdata: selectedunblock,
      UserId: ghuserId,
      PropertyId: property_id[0]?.Id,
      CheckIn24Hrs: 0,
    };
    isLoadingfunc(true);

    // console.log(bodyunblockdata)
    // console.log(headers)
    axios

      .post(`${proxy_Url}/bulkunblock`, bodyunblockdata, { headers: headers })
      .then(({ data }) => {
        setbookinggetsuccessErrorMessage(["Room unblocked successfully"]);
        setTimeout(() => {
          setbookinggetsuccessErrorMessage([]);
        }, 2000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        ghnamechange();
        isLoadingfunc(false);
        selectedallfunc_bulk([]);
        // console.log("first")
      })
      .catch((error) => {
        console.log(error.message);
        isLoadingfunc(false);
      });
  };

  const selectunblockallfunc = () => {
    if (selectedunblock?.length > 0) {
      setunblockallconfirm(["Do you want to unblock all selected room's?"]);
    } else {
      setunblockallconfirm([]);
      seterrormsg(["Select room no to unblock room's"]);
      setTimeout(() => {
        seterrormsg([]);
      }, 1000);
    }
  };

  const disablekeyboard =() => {
    document.onkeydown = function (e) {
      return false;
}
  }

  const enablekeyboard =() => {
    document.onkeydown = function (e) {
      return true;
}
  }

  return (
    <React.Fragment>
      {is_loading && <SpinnerTemplate />}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
       </div> */}
            </div>
            <div className="error_content">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* unblock all yes or no */}
      {unblockallconfirm?.length > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">Do you want to unblock all selected room's?</p>
            </div>

            <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but" onClick={unblockdatafunc}>
              Yes
            </button>

            <button
              className="btn btn-sm btn-warning mt-n1 mb-2  continue-but"
              onClick={() => setunblockallconfirm([])}
            >
              No
            </button>
          </div>
        </div>
      )}

      {modifysuccessMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con error-success">
              <span>Success Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
       </div> */}
            </div>
            <div className="error_content t-center">
              {modifysuccessMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {errormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {errormsg &&
                errormsg?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {bookinggetErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span>Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {bookinggetErrorMessage &&
                bookinggetErrorMessage?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {bookinggetsuccessErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con error-success`}>
              <span>Success Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
      </div> */}
            </div>
            <div className="error_content">
              {bookinggetsuccessErrorMessage &&
                bookinggetsuccessErrorMessage?.map((item, i) => (
                  <p className="t-center" style={{ width: "100% !important" }} key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {blockmodify?.length > 0 && <BlockModifyData ghnamechange={ghnamechange} />}
      {blockhistory?.length > 0 && block_htry && <BlockHistory setblockhtry={setblockhtry} />}
      <Head title="Guest house booking"></Head>
      {/* {showerror_template && <Errow_page />} */}
      <Content>
        <h3 className="page_title">Room Block </h3>

        <div className="bg-white p-1 card-bordered b-form-details roomblock">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className="row">
                <div className="col-md-12">
                  <label className="e-textlabel">
                    Guest House <span className="c-red">&nbsp;*</span>
                  </label>

                  <AutoCompleteComponent
                    id="booking_ghname"
                    value={formValues?.GHName}
                    placeholder="Select the Guest House"
                    className="form-control"
                    //onChange={ghchange}
                    onChange={ghnamechange}
                    dataSource={ghName && ghName[0]?.map((item, key) => item.Property.trim())}
                    filtering={ghnamechange}
                    ref={propertyloadref}
                    name="GHName"
                    // onMouseLeave={ghnameBlur}
                    // onPaste={e => e.preventDefault()}
                    // onCopy={e => e.preventDefault()}
                    autoComplete="off"
                    select={handleSelect}
                  />
                </div>
                <div className="col-md-12">
                  <label className="e-textlabel">Blocked From Date</label>
                  <div className="form-control-wrap">
                    <DatePickerComponent
                      id="blockfromDate"
                      format="dd/MM/yyyy"
                      data-name="blockfromDate"
                      onFocus={onFocussdate}
                      onChange={(e) => HandleChange(e)}
                      name="blockfromDate"
                      min={new Date()}
                      ref={(date) => (startObj = date)}
                      max={maxdate}
                      value={formValues.blockfromDate || new Date()}
                      className="form-control"
                    ></DatePickerComponent>
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="e-textlabel">Blocked To Date</label>
                  <div className="form-control-wrap">
                    <DatePickerComponent
                      id="blocktoDate"
                      format="dd/MM/yyyy"
                      data-name="blocktoDate"
                      onFocus={onFocusstodate}
                      min={Propertyflag?.CheckIn_24HrsFlg === true ? mindate24 : mindate}
                      onChange={HandleoutChange}
                      name="blocktoDate"
                      ref={(date) => (endObj = date)}
                      value={formValues.blocktoDate || new Date(new Date().setDate(new Date().getDate() + 1))}
                      className="form-control"
                    ></DatePickerComponent>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="row">
                <div
                  className={`col-md-12 blocktimebgcolor  pl-md-0 ${
                    Propertyflag?.CheckIn_24HrsFlg !== true && "nopointerevents"
                  }`}
                >
                  <label htmlFor="Check_In_Date">Blocked From Time</label>

                  <TimePickerComponent
                    id="blockintime"
                    openOnFocus={true}
                    onClick={onFocussfromtime}
                    onChange={onTimeChange}
                    min={"12:00 PM"}
                    //selected={"12:00 PM"  }
                    ref={(scope) => (intimeobj = scope)}
                    step={60}
                    value={intime_change || "12:00 PM"}
                    placeholder="Select a Time"
                    // focus={onFocussfromtime}
                    onFocus={disablekeyboard}
                              onBlur={enablekeyboard}
                  />
                </div>
                <div
                  className={`col-md-12 blocktimebgcolor  pl-md-0 ${
                    Propertyflag?.CheckIn_24HrsFlg !== true && "nopointerevents"
                  }`}
                >
                  <label htmlFor="blockouttime">Blocked To Time</label>

                  <TimePickerComponent
                    id="blockouttime"
                    openOnFocus={true}
                    onClick={onFocusstotime}
                    onChange={onoutTimeChange}
                   // min={intime_change}
                    //selected={"12:00 PM"  }
                    ref={(scope) => (outtime = scope)}
                    step={60}
                    value={outtime_change || "11:59 AM"}
                    placeholder="Select a Time"
                    // focus={onFocusstotime}
                    onFocus={disablekeyboard}
                              onBlur={enablekeyboard}
                  />
                </div>
                <div className="col-md-12 pl-md-0">
                  <label className="e-textlabel">
                    Comments <span className="c-red"> &nbsp;*</span>
                  </label>
                  <textarea
                    type="text"
                    name="blockroom"
                    defaultValue={formValues.blockroom || ""}
                    className="form-control hide_emp"
                    id="blockroom"
                    // onChange={handelChange}
                    style={{ minHeight: "110px" }}
                  ></textarea>
                  <button
                    style={{ marginTop: "30px" }}
                    type="button"
                    className="btn btn btn-info float-right"
                    onClick={selectoptionprop}
                  >
                    Check available rooms
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 block-room-blk">
              <div className="row">
                <div className="col-md-12 pl-md-0 pt-0">
                  <label className="e-textlabel">
                    Select Block Room Names <span className="c-red"> &nbsp;*</span>
                  </label>
                  {roomdataDetails?.length > 0 && (
                    <CheckboxGroup value={selectedall} onChange={selectedallfunc} className="chx-box">
                      <Checkbox value={"Select All"} style={{ float: "right" }} onChange={selectallfunc}>
                        {" "}
                        &nbsp;{"Select All"}
                      </Checkbox>
                    </CheckboxGroup>
                  )}
                </div>
                <CheckboxGroup
                  className="toppings-list"
                  label="Favorite sports (controlled)"
                  value={selected}
                  onChange={setSelected}
                >
                  {roomdataDetails?.length > 0 ? (
                    roomdataDetails?.map((item) => (
                      <Checkbox key={item?.RoomId} value={item?.RoomId}>
                        {" "}
                        &nbsp;{item?.RoomNoId}
                      </Checkbox>
                    ))
                  ) : (
                    <p style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px" }}>{roomnotavailable}</p>
                  )}
                </CheckboxGroup>
              </div>
            </div>

            <div className="col-md-12 text-center mt-2 border-top">
              <div className="btnGrp mt-1">
                <button className="selectsingleTr btn btn-primary" onClick={history}>
                  History
                </button>
                <button type="button" className="btn  btn-success m-1" onClick={onSubmit}>
                  Block rooms
                </button>
                {/* <button type="button" className="btn btn-sm btn-danger m-6" onClick={onDownloadfunc}>
                    Export
                  </button> */}
                <button type="button" className="btn btn-warning m-1" onClick={clearData}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        {blockedRooms && blockedRooms !== "N" && blockedRooms?.length > 0 && (
          <>
            <div className="row">
              <div className="col-md-12 tr_list_table mt-4">
                <div className="o_flow h-26 datashow bg-white">
                  <span className="w-100 stickyheader2" style={{ position: "sticky", top: "2px" }}>
                    <div className="header">
                      <span className="w-100">
                        <span style={{ marginLeft: "10px" }}>Blocked Room List</span>
                      </span>
                      <span>
                        <input
                          type="search"
                          name="ghpreopretySearch"
                          defaultValue=""
                          placeholder="Blocked room search"
                          className="ghpropertySearch ml-1 mt-n1"
                          onChange={(e) => RoomBlock(e.target.value)}
                          ref={trselectref}
                        />
                        <button
                          className="ghpropertySearch_blkbut btn btn-primary btn-md ml-1 ml-2 mr-0 mt-n1 bulkunblockbut"
                          onClick={selectunblockallfunc}
                        >
                          Bulk Unblock
                        </button>
                      </span>
                    </div>
                  </span>
                  {property_room_filter.length > 0 ? (
                    <table
                      role="table"
                      className="table table-hover table-bordered tr_select roomblock roombtn booking_details b-shadow w-100"
                    >
                      <thead role="rowgroup">
                        <tr role="row" className="stickyheader1">
                          <th role="columnheader">Room&nbsp;No</th>
                          <th role="columnheader">From Date</th>
                          <th role="columnheader">To Date</th>
                          <th role="columnheader">Comments</th>
                          <th role="columnheader" style={{ justifyContent: "center", display: "flex" }}>
                            <CheckboxGroup value={selectedall_bulk} onChange={selectedallfunc_bulk} className="chx-box">
                              <Checkbox value={"Unblock All"} style={{ float: "right" }} onChange={selectallfunc_bulk}>
                                {" "}
                                &nbsp;{"Unblock All"}
                              </Checkbox>
                            </CheckboxGroup>
                          </th>
                          <th role="columnheader">Modify</th>
                          <th role="columnheader" style={{ width: "100px" }}>
                            Unblock
                          </th>
                        </tr>
                      </thead>
                      <tbody role="rowgroup">
                        {property_room_filter.length > 0 && property_room_filter !== "N" ? (
                          property_room_filter?.map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">
                                <span className="block-colon"> : </span>
                                {val.RoomNo}
                              </td>
                              <td role="cell" style={{ whiteSpace: "nowrap" }}>
                                <span className="block-colon"> : </span>
                                {val.FromDt +
                                  " " +
                                  (val.FromTime.includes("AM")
                                    ? val.FromTime?.replace("AM", " AM")
                                    : val.FromTime?.replace("PM", " PM"))}
                              </td>
                              <td role="cell" style={{ whiteSpace: "nowrap" }}>
                                <span className="block-colon"> : </span>
                                {val.ToDt +
                                  " " +
                                  (val.ToTime.includes("AM")
                                    ? val.ToTime?.replace("AM", " AM")
                                    : val.ToTime?.replace("PM", " PM"))}
                              </td>
                              <td role="cell">
                                <span className="block-colon"> : </span> {val.Comments}
                              </td>
                              <td role="cell">
                                <CheckboxGroup
                                  className="toppings-list_unblock"
                                  label="Favorite sports (controlled)1"
                                  value={selectedunblock}
                                  onChange={setSelectedunblock}
                                  style={{}}
                                >
                                  <Checkbox
                                    key={index}
                                    value={val?.RoomId + " " + val.FromDt + " " + val.ToDt}
                                  ></Checkbox>
                                </CheckboxGroup>
                              </td>
                              <td role="cell" className="blockbut" style={{ width: "70px" }}>
                                <span className="block-colon"> : </span>
                                <button
                                  className="selectsingleTr btn btn-success btn-sm mx-2"
                                  onClick={(e) => modify(val)}
                                  disabled={val.CurrentStatus === "CheckIn" ? true : false}
                                >
                                  Modify
                                </button>
                              </td>
                              <td role="cell">
                                <span className="block-colon"> : </span>
                                <button
                                  className="selectsingleTr btn btn-primary btn-sm ml-1 ml-2 unblockbtn"
                                  onClick={(e) => unblock(val)}
                                  disabled={val.CurrentStatus === "CheckIn" ? true : false}
                                >
                                  Unblock
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <p className="d-flex text-align-center justify-content-center mt-3 m-auto">
                                No records found
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p className="nodatadisplay">No records found</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default RoomBlock;
